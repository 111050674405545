'use strict';

angular.module('appApp')
  .controller('ProductCtrl', function ($scope, $stateParams, $http, Auth, Modal, $rootScope) {
    $rootScope.product = { id: $stateParams.id, user: $stateParams.user };

    $scope.vars = {
      labelGen: false,
      techSheetGen: false,
      loadAnimation: false,
      id: $stateParams.id,
      user: $stateParams.user,
      noExternalIngredientsInSearch: false,
      favoriteIngredients: [],
      data: {},
      totalPrice: 0,
      tableConfig: {},
      priceTableConfig: {},
      ingredientsTableConfig: {},
      quidsTableConfig: {},
      ingredients: [],
      searchList: [],
      searchOwnOnly: false,
      searchFavsOnly: false,
      nutrientsPer100g: {
        quantity: 0,
        energyKcal: 0,
        energyKJ: 0,
        fats: 0,
        satFats: 0,
        monoinsatFats: 0,
        polyinsatFats: 0,
        carbohydrates: 0,
        sugarCarbs: 0,
        polyol: 0,
        starch: 0,
        fibres: 0,
        proteins: 0,
        salts: 0
      },
      nutrientsPerPiece: {
        quantity: 0,
        energyKcal: 0,
        energyKJ: 0,
        fats: 0,
        satFats: 0,
        monoinsatFats: 0,
        polyinsatFats: 0,
        carbohydrates: 0,
        sugarCarbs: 0,
        polyol: 0,
        starch: 0,
        fibres: 0,
        proteins: 0,
        salts: 0
      },
      gda: {
        energyKcal: 0,
        fats: 0,
        satFats: 0,
        carbohydrates: 0,
        sugarCarbs: 0,
        fibres: 0,
        proteins: 0,
        salts: 0
      },
      gdaInfo: {
        energyKcal: 2000,
        fats: 70,
        satFats: 20,
        carbohydrates: 260,
        sugarCarbs: 90,
        fibres: 25,
        proteins: 50,
        salts: 6
      },
      traceLabels: {
        sedano: 'Sedano',
        crostacei: 'Crostacei',
        uova: 'Uova',
        pesce: 'Pesce',
        glutine: 'Glutine',
        lupini: 'Lupini',
        latte: 'Latte',
        molluschi: 'Molluschi',
        senape: 'Senape',
        fruttiAGuscioNoci: 'Frutti a guscio / Noci',
        arachidi: 'Arachidi',
        semiDiSesamo: 'Semi di sesamo',
        soia: 'Soia',
        anidrideSolfosaSolfiti: 'Anidride solfosa / Solfiti'
      },
      ownSemifinished: [],
      labelDimensions: [
        { n: 1, label: 'XXL' },
        { n: 2, label: 'XL' },
        { n: 4, label: 'L' },
        { n: 12, label: 'M' },
        { n: 16, label: 'S' },
        { n: 18, label: 'XS' },
        { n: 24, label: 'XXS' },
        { n: 27, label: 'XXXS' }
      ],
      language: 'Italiano',
      languages:[
        'Italiano',
        'Inglese',
        'Tedesco',
        'Francese'
      ],
      translationsForUnitTypes: {
        pezzo: {
          Italiano: 'PEZZO',
          Inglese: 'PIECE',
          Tedesco: 'STÜCK',
          Francese: 'PIÈCE'
        },
        confezione: {
          Italiano: 'CONFEZIONE',
          Inglese: 'PACKAGE',
          Tedesco: 'PACKAGE',
          Francese: 'PAQUET'
        },
        vasetto: {
          Italiano: 'VASETTO',
          Inglese: 'JAR',
          Tedesco: 'GLAS',
          Francese: 'POT'
        },
        porzione: {
          Italiano: 'PORZIONE',
          Inglese: 'PORTION',
          Tedesco: 'TEIL',
          Francese: 'PARTIE'
        },
        pacco: {
          Italiano: 'PACCO',
          Inglese: 'PACK',
          Tedesco: 'PAKET',
          Francese: 'PARCELLE'
        }
      },
      translationsForMaterials: {
        Carta: {
          Italiano: 'CARTA',
          Inglese: 'PAPER',
          Tedesco: 'CHARTA',
          Francese: 'CHARTE'
        },
        Cartone: {
          Italiano: 'CARTONE',
          Inglese: 'CARDBOARD',
          Tedesco: 'KARTON',
          Francese: 'CARTON'
        },
        Vetro: {
          Italiano: 'VETRO',
          Inglese: 'GLASS',
          Tedesco: 'GLAS',
          Francese: 'VERRE'
        },
        Plastica: {
          Italiano: 'PLASTICA',
          Inglese: 'PLASTIC',
          Tedesco: 'KUNSTSTOFF',
          Francese: 'PLASTIQUE'
        },
        Lattine: {
          Italiano: 'LATTINE',
          Inglese: 'CANS',
          Tedesco: 'BÜCHSEN',
          Francese: 'CANS'
        },
      },
      barcodePreviewState: 'noData'
    };

    $http.get('/api/products/' + $scope.vars.id).then(res => {
      if(!res.data.productImgFile) {
        res.data.productImgFile = {
          id: 'product-img',
          label: 'Immagine del prodotto (formato PNG, JPG/JPEG o PDF)',
          value: '',
          userId: Auth.getCurrentUser()._id,
          preview: true
        };
      }

      $scope.vars.data = res.data;
      $scope.vars.barcodePreviewState = $scope.vars.data.barcodeData ? 'display' : 'noData';
      $scope.barcodeChanged(true);

      $scope.vars.tableConfig.options = {
        data: $scope.vars.data.ingredients,
        idField: 'name',
        search: false,
        maintainSelected: true,
        pagination: true,
        checkboxHeader: false,
        columns: [{
          field: 'id',
          title: 'Ingredienti',
          width: '80%',
          valign: 'middle',
          sortable: true,
          formatter: function(value, row) {
            if(row.multilingual) {
              const nameField = 'name' + $scope.langToCod($scope.vars.language);
              return row[nameField];
            } else {
              return row.name;
            }
          }
        }, {
          field: 'quantity',
          title: 'Quantità (g)',
          align: 'right',
          valign: 'middle',
          sortable: true,
          editable: {
            clear: false,
            showbuttons: 'bottom',
            unsavedclass: null,
            type: 'number',
            step: 'any',
            defaultValue: 100,
            inputclass: 'form-control',
            title: 'Modifica quantità'
          }
        }, {
          field: 'quantity',
          title: '% quantità',
          align: 'right',
          valign: 'middle',
          sortable: true,
          formatter: e => {
            const total = $scope.vars.data.ingredients.reduce((a, b) =>
              a + b.quantity, 0);
            return ((e / total) * 100).toFixed(2) + '%';
          }
        }, {
          field: 'includePerc',
          title: '&nbspQUID*&nbsp',
          align: 'center',
          valign: 'middle',
          checkbox: true
        }, {
          field: 'actions',
          title: '',
          align: 'center',
          valign: 'middle',
          events: {
            'click .delete': function (e, value, row, index) {
              $scope.vars.data.ingredients.splice(index, 1);
              $scope.refreshProductInfo();
              $scope.$digest();
              $scope.saveProduct();
              $scope.refreshQuidTables();
            }
          },
          formatter: function() {
            return [
              '<a class="delete" href="javascript:void(0)" title="Elimina l\'ingrediente">',
              '<i class="glyphicon glyphicon-remove"></i>',
              '</a>'
            ].join('');
          }
        }]
      };

      $scope.vars.priceTableConfig.options = {
        data: $scope.vars.data.ingredients,
        idField: 'name',
        search: false,
        maintainSelected: true,
        pagination: true,
        checkboxHeader: false,
        columns: [{
          field: 'id',
          title: 'Ingredienti',
          width: '80%',
          valign: 'middle',
          sortable: true,
          formatter: function(value, row) {
            if(row.multilingual) {
              const nameField = 'name' + $scope.langToCod($scope.vars.language);
              return row[nameField];
            } else {
              return row.name;
            }
          }
        }, {
          field: 'quantity',
          title: 'Quantità (g)',
          align: 'right',
          valign: 'middle',
          sortable: true
        }, {
          field: 'pricePerKg',
          title: '€ / kg (1)',
          align: 'right',
          valign: 'middle',
          sortable: true,
          editable: {
            clear: false,
            showbuttons: 'bottom',
            unsavedclass: null,
            type: 'number',
            step: 'any',
            defaultValue: 1,
            inputclass: 'form-control',
            title: 'Modifica prezzo (€) per kg'
          }
        }, {
          field: 'pricePerKg',
          title: '€ / quantità (2)',
          align: 'right',
          valign: 'middle',
          sortable: true,
          formatter: function(value, row) {
            return value * row.quantity / 1000;
          }
        }]
      };

      $scope.vars.ingredientsTableConfig.options = {
        data: $scope.vars.data.ingredients,
        idField: 'id',
        search: false,
        maintainSelected: true,
        pagination: true,
        checkboxHeader: false,
        columns: [{
          field: 'id',
          title: 'Ingredienti',
          width: '30%',
          valign: 'middle',
          sortable: true,
          formatter: function(value, row) {
            return row.name;
          }
        }, {
          field: 'displayName',
          title: 'Denominazione su etichetta',
          valign: 'middle',
          sortable: true,
          editable: {
            clear: false,
            showbuttons: 'bottom',
            unsavedclass: null,
            type: 'text',
            inputclass: 'form-control',
            title: 'Denominazione su etichetta',
            emptytext: 'Denominazione vuota',
            validate: function(value) {
              if($.trim(value) == '') {
                return 'Denominazione non valida';
              }
            }
          }
        }, {
          field: 'visibility',
          title: '&nbspVisibile&nbsp',
          align: 'center',
          valign: 'middle',
          checkbox: true,
          formatter: function(value, row) {
            return row.visible !== false;
          }
        }]
      };

      $scope.vars.quidsTableConfig.options = {
        data: $scope.vars.data.ingredients.filter(e => e.includePerc),
        idField: 'id',
        search: false,
        maintainSelected: true,
        pagination: true,
        checkboxHeader: false,
        columns: [{
          field: 'id',
          title: 'Ingredienti',
          width: '30%',
          valign: 'middle',
          sortable: true,
          formatter: function(value, row) {
            return row.name;
          }
        // }, {
        //   field: 'id',
        //   title: 'QUID',
        //   valign: 'middle',
        //   sortable: true,
        //   formatter: function(value, row) {
        //     const quantity = row.quantity;
        //     const quid = 100 * quantity / $scope.vars.data.totalWeightPostPrep;
        //     return quid.toFixed(2);
        //   }
        }, {
          field: 'quidWording',
          title: 'Dicitura QUID',
          valign: 'middle',
          sortable: true,
          editable: {
            type: 'select',
            title: 'Dicitura QUID',
            sourceError: 'Please, select a State',
            source: function() {
              const prodName = $scope.vars.data.name;
              const name = $(this).parent().siblings('td:first').text();
              const ingredient = $scope.vars.data.ingredients
                .find(e => e.name === name);
              const quantity = ingredient.quantity;
              const total = $scope.vars.data.totalWeightPostPrep ||
                $scope.vars.data.ingredients.reduce((a, b) => a + b.quantity, 0);
              const quid = 100 * quantity / total;

              if(quid >= 100) {
                const lastChar = name.trim().slice(-1).toLowerCase();
                const ending = ['a', 'e', 'i'].includes(lastChar) ? lastChar : 'o';

                return [
                  { value: 1, text: name + ' utilizzat' + ending + ' per 100g di prodotto: ' + quantity + 'g' },
                  { value: 2, text: 'Per la preparazione di 100g di ' + prodName + ' sono stati utilizzati ' + quantity + 'g di ' + name }
                ];
              } else {
                return [
                  { value: 1, text: name + ' (' + quid.toFixed(2) + '%)' },
                  { value: 2, text: name + ' (' + quid.toFixed(2) + '%)' }
                ];
              }
            }
          }
        }]
      };

      $scope.refreshProductInfo(true);
      $scope.initLabelEditor();
      $scope.loadLabelSettings();
      $scope.loadTechSheetSettings();
    });

    $http.get('/api/user-settings/' + Auth.getCurrentUser()._id).then((res) => {
      $scope.vars.noExternalIngredientsInSearch =
        res.data.noExternalIngredientsInSearch;
      $scope.vars.favoriteIngredients = res.data.favoriteIngredients;
    });

    $scope.addIngredient = function() {
      const isIngredient = $scope.vars.toAdd.id;

      if(!isIngredient) {
        // console.log('NOT AN INGREDIENT');
        const ingFromName = $scope.vars.searchList.find(e =>
          e.titolo.toLowerCase() === $scope.vars.toAdd.toLowerCase());
        if(!ingFromName) { return; }
        $scope.vars.toAdd = ingFromName;
      }

      const present = $scope.vars.data.ingredients.some(e =>
        e.id === $scope.vars.toAdd.id);

      if(present) {
        // console.log('INGREDIENT ALREADY PRESENT!');
        return;
      }

      // console.log('INGREDIENT NOT FOUND!');

      const newIngredient = {
        id: $scope.vars.toAdd.id,
        name: $scope.vars.toAdd.titolo,
        nameD: $scope.vars.toAdd.titoloD,
        nameF: $scope.vars.toAdd.titoloF,
        nameI: $scope.vars.toAdd.titoloI,
        nameE: $scope.vars.toAdd.titoloE,
        multilingual: $scope.vars.toAdd.multilingual,
        allergen: !!$scope.vars.toAdd.allergenico,
        info: {
          type: $scope.vars.toAdd.tipo,
          energiaRicalcolataKcal: $scope.vars.toAdd.energiaRicalcolataKcal,
          energiaRicalcolataKJ: $scope.vars.toAdd.energiaRicalcolataKJ,
          lipidiTotaliG: $scope.vars.toAdd.lipidiTotaliG,
          lipidiAnimaliG: $scope.vars.toAdd.lipidiAnimaliG,
          grassiMonoinsaturiG: $scope.vars.toAdd.grassiMonoinsaturiG,
          grassiPolinsaturiG: $scope.vars.toAdd.grassiPolinsaturiG,
          carboidratiDisponibiliMSEG: $scope.vars.toAdd.carboidratiDisponibiliMSEG,
          carboidratiSolubiliMSEG: $scope.vars.toAdd.carboidratiSolubiliMSEG,
          polioliG: $scope.vars.toAdd.polioliG,
          amidoG: $scope.vars.toAdd.amidoG,
          fibraAlimentareTotaleG: $scope.vars.toAdd.fibraAlimentareTotaleG,
          proteineTotaliG: $scope.vars.toAdd.proteineTotaliG,
          sodioMg: $scope.vars.toAdd.sodioMg,
          ingredients: $scope.vars.toAdd.ingredienti,
          allergens: $scope.vars.toAdd.listaAllergeni,
          allergenParts: $scope.vars.toAdd.parteAllergenica,
          ingredientsInfo: $scope.vars.toAdd.infoIngredienti,
          ownRatio: $scope.vars.toAdd.ownRatio,
          aromaDeclarationInfo: $scope.vars.toAdd.aromaInfoDichiarazione,
          aromaExtras: $scope.vars.toAdd.aromaAdditiviSolventiAltro
        },
        quantity: 100,
        includePerc: false,
        pricePerKg: 1
      };

      $scope.vars.data.ingredients.push(newIngredient);

      if(!$scope.vars.toAdd.listaTracce) {
        $scope.vars.toAdd.listaTracce = {
          sedano: false,
          crostacei: false,
          uova: false,
          pesce: false,
          glutine: false,
          lupini: false,
          latte: false,
          molluschi: false,
          senape: false,
          fruttiAGuscioNoci: false,
          arachidi: false,
          semiDiSesamo: false,
          soia: false,
          anidrideSolfosaSolfiti: false
        };
      }

      if(!$scope.vars.data.traces) {
        $scope.vars.data.traces = {
          sedano: false,
          crostacei: false,
          uova: false,
          pesce: false,
          glutine: false,
          lupini: false,
          latte: false,
          molluschi: false,
          senape: false,
          fruttiAGuscioNoci: false,
          arachidi: false,
          semiDiSesamo: false,
          soia: false,
          anidrideSolfosaSolfiti: false
        };
      }

      const newTraces = Object.keys($scope.vars.toAdd.listaTracce)
        .filter(key => $scope.vars.toAdd.listaTracce[key]);
      newTraces.push(...$scope.vars.toAdd.tracce);
      newTraces.filter(e => e in $scope.vars.data.traces)
        .forEach(e => $scope.vars.data.traces[e] = true);

      $scope.vars.toAdd = null;
      $scope.refreshProductInfo();
      $scope.saveProduct();
      $scope.refreshQuidTables();
    };

    $scope.saveProduct = function(callback) {
      // console.log($scope.vars.data);
      $http.put('/api/products/' + $scope.vars.id, $scope.vars.data).then(() => {
        // console.log('PRODUCT SAVED!');
        $http.get('/api/products/' + $scope.vars.id).then(res => {
          $scope.vars.data = res.data;
          $scope.vars.tableConfig.options.data = $scope.vars.data.ingredients;
          $scope.vars.priceTableConfig.options.data = $scope.vars.data.ingredients;
          $scope.vars.ingredientsTableConfig.options.data = $scope.vars.data.ingredients;
          $scope.refreshQuidTables();
          // console.log($scope.vars.data);

          if(callback) {
            callback();
          }
        });
      });
    };

    $scope.toggleIngredientVisibility = function(id, vis) {
      const elem = $scope.vars.data.ingredients.find(e => e.id === id);

      if(elem) {
        elem.visible = vis;
        $scope.saveProduct();
      }
    };

    // INIT
    $(function() {
      const $table = $('#table');
      const $priceTable = $('#priceTable');
      const $quidsTable = $('#quidsTable');
      const $quidsTableTS = $('#quidsTableTS');

      $table.on('editable-save.bs.table', function($el, field, row) {
        for(var i = 0; i < $scope.vars.data.ingredients.length; i++) {
          if($scope.vars.data.ingredients[i].id === row.id) {
            $scope.vars.data.ingredients[i].quantity = parseFloat(row.quantity);
            $scope.refreshProductInfo();
            $scope.saveProduct();
            $scope.refreshQuidTables();
            break;
          }
        }
      });

      $table.on('check.bs.table', function($el, row) {
        includePercStateChanged(row);
      });

      $table.on('uncheck.bs.table', function($el, row) {
        includePercStateChanged(row);
      });

      function includePercStateChanged(row) {
        const ingredient = $scope.vars.data.ingredients.find(e =>
          e.id === row.id);
        ingredient.includePerc = row.includePerc;
        $scope.refreshProductInfo();
        $scope.saveProduct();
        $scope.refreshQuidTables();
      }

      $priceTable.on('editable-save.bs.table', function($el, field, row) {
        for(var i = 0; i < $scope.vars.data.ingredients.length; i++) {
          if($scope.vars.data.ingredients[i].id === row.id) {
            $scope.vars.data.ingredients[i].pricePerKg = parseFloat(row.pricePerKg);
            $scope.saveProduct();
            break;
          }
        }

        $scope.recalculateTotalPrice();
      });

      $('#ingredientsTable').on('editable-save.bs.table', function($el, field, row) {
        const elem = $scope.vars.data.ingredients.find(e => e.id === row.id);
        if(elem) { elem.displayName = row.displayName.trim(); }
        $scope.saveProduct();
      });

      $('#ingredientsTable').on('check.bs.table', function(e, row, $el) {
        $scope.toggleIngredientVisibility(row.id, true);
      });

      $('#ingredientsTable').on('uncheck.bs.table', function(e, row, $el) {
        $scope.toggleIngredientVisibility(row.id, false);
      });

      $('#ingredientsTableTS').on('editable-save.bs.table', function($el, field, row) {
        const elem = $scope.vars.data.ingredients.find(e => e.id === row.id);
        if(elem) { elem.displayName = row.displayName.trim(); }
        $scope.saveProduct();
      });

      $('#ingredientsTableTS').on('check.bs.table', function(e, row, $el) {
        $scope.toggleIngredientVisibility(row.id, true);
      });

      $('#ingredientsTableTS').on('uncheck.bs.table', function(e, row, $el) {
        $scope.toggleIngredientVisibility(row.id, false);
      });

      $scope.resetQuidTableState($quidsTable, $scope.vars.data);

      $quidsTable.on('editable-save.bs.table', function($el, field, row) {
        const elem = $scope.vars.data.ingredients.find(e => e.id === row.id);
        if(elem) { elem.quidWording = row.quidWording; }
        $scope.saveProduct();
        $scope.resetQuidTableState($quidsTable, $scope.vars.data);
      });

      $quidsTable.on('reset-view.bs.table', function($el, field, row) {
        $scope.resetQuidTableState($quidsTable, $scope.vars.data);
      });

      $scope.resetQuidTableState($quidsTableTS, $scope.vars.data);

      $quidsTableTS.on('editable-save.bs.table', function($el, field, row) {
        const elem = $scope.vars.data.ingredients.find(e => e.id === row.id);
        if(elem) { elem.quidWording = row.quidWording; }
        $scope.saveProduct();
        $scope.resetQuidTableState($quidsTableTS, $scope.vars.data);
      });

      $quidsTableTS.on('reset-view.bs.table', function($el, field, row) {
        $scope.resetQuidTableState($quidsTableTS, $scope.vars.data);
      });

      // GRIDSTACK
      // $('.grid-stack').gridstack();
    });

    $scope.resetQuidTableState = function(table, recipe) {
      setTimeout(function() {
        const tableRows = table.find('tbody tr');

        tableRows.each(function(index) {
          const name = $(this).find('td:first').text();
          const ingredient = recipe.ingredients
            .find(e => e.name === name);

          if(ingredient) {
            const quantity = ingredient.quantity;
            const total = recipe.totalWeightPostPrep ||
              recipe.ingredients.reduce((a, b) => a + b.quantity, 0);
            const quid = 100 * quantity / total;
            const disabled = !ingredient.includePerc || (quid < 100);

            if(disabled) {
              $(this).find('td:last-child a').editable('toggleDisabled');
            }
          }
        });
      }, 100);
    };

    $scope.someQuid = function(ingredients) {
      return !!ingredients && ingredients.some(e => e.includePerc);
    };

    $scope.refreshQuidTables = function() {
      $('#quidsTable').bootstrapTable('load', $scope.vars.data.ingredients
        .filter(e => e.includePerc));
      $('#quidsTableTS').bootstrapTable('load', $scope.vars.data.ingredients
        .filter(e => e.includePerc));
    }

    $scope.recalculateTotalPrice = () => {
      $scope.vars.priceTotal =
        $scope.vars.data.ingredients.reduce((a, b) => {
          return a + b.pricePerKg * b.quantity / 1000;
        }, 0);
    };

    $scope.calculateNutrientsFor100g = function() {
      var nutrientsPer100g = {
        quantity: 0,
        energyKcal: 0,
        energyKJ: 0,
        fats: 0,
        satFats: 0,
        monoinsatFats: 0,
        polyinsatFats: 0,
        carbohydrates: 0,
        sugarCarbs: 0,
        polyol: 0,
        starch: 0,
        fibres: 0,
        proteins: 0,
        salts: 0,
        ratio: 1
      };

      var ingredientInfo;

      for(var i = 0; i < $scope.vars.data.ingredients.length; i++) {
        ingredientInfo = $scope.vars.data.ingredients[i];
        nutrientsPer100g.quantity += parseFloat(ingredientInfo.quantity);
      }

      var list = [];

      $scope.vars.data.ingredients.forEach(e => {
        if(e.info.ingredientsInfo) {
          const sfTot = e.info.ingredientsInfo.reduce((a, b) => a + b.quantity, 0);
          e.info.ingredientsInfo.forEach(i => i.quantity *= e.quantity / sfTot);

          if(e.info.ownRatio) {
            e.info.ingredientsInfo.forEach(i => i.ownRatio = e.info.ownRatio);
          }

          list = list.concat(e.info.ingredientsInfo);
        } else {
          list.push(e);
        }
      });

      if($scope.vars.data.totalWeightPostPrep && $scope.vars.data.totalWeightPostPrep > 0) {
        nutrientsPer100g.ratio = nutrientsPer100g.quantity / $scope.vars.data.totalWeightPostPrep;
      }

      for(i = 0; i < list.length; i++) {
        ingredientInfo = list[i];
        var quantity = parseFloat(ingredientInfo.quantity);
        var ingredient = ingredientInfo.info;
        var ownRatio = ingredientInfo.ownRatio || 1;
        var mult = (quantity / nutrientsPer100g.quantity) *
          nutrientsPer100g.ratio * ownRatio;

        nutrientsPer100g.energyKcal += $scope.getValidValue(ingredient.energiaRicalcolataKcal * mult);
        nutrientsPer100g.energyKJ += $scope.getValidValue(ingredient.energiaRicalcolataKJ * mult);
        nutrientsPer100g.fats += $scope.getValidValue(ingredient.lipidiTotaliG * mult);
        nutrientsPer100g.satFats += $scope.getValidValue(ingredient.lipidiAnimaliG * mult);
        nutrientsPer100g.monoinsatFats += $scope.getValidValue(ingredient.grassiMonoinsaturiG * mult);
        nutrientsPer100g.polyinsatFats += $scope.getValidValue(ingredient.grassiPolinsaturiG * mult);
        nutrientsPer100g.carbohydrates += $scope.getValidValue(ingredient.carboidratiDisponibiliMSEG * mult);
        nutrientsPer100g.sugarCarbs += $scope.getValidValue(ingredient.carboidratiSolubiliMSEG * mult);
        nutrientsPer100g.polyol += $scope.getValidValue(ingredient.polioliG * mult);
        nutrientsPer100g.starch += $scope.getValidValue(ingredient.amidoG * mult);
        nutrientsPer100g.fibres += $scope.getValidValue(ingredient.fibraAlimentareTotaleG * mult);
        nutrientsPer100g.proteins += $scope.getValidValue(ingredient.proteineTotaliG * mult);
        nutrientsPer100g.salts += $scope.getValidValue(ingredient.sodioMg / 1000 * mult * 2.5);
      }

      $scope.normaliseNutritionalValues(nutrientsPer100g, 100);
      $scope.vars.nutrientsPer100g = nutrientsPer100g;
    };

    $scope.calculateNutrientsForPiece = function() {
      var nutrientsPerPiece = {
        quantity: 0,
        energyKcal: 0,
        energyKJ: 0,
        fats: 0,
        satFats: 0,
        monoinsatFats: 0,
        polyinsatFats: 0,
        carbohydrates: 0,
        sugarCarbs: 0,
        polyol: 0,
        starch: 0,
        fibres: 0,
        proteins: 0,
        salts: 0
      };

      var ingredientInfo;

      for(var i = 0; i < $scope.vars.data.ingredients.length; i++) {
        ingredientInfo = $scope.vars.data.ingredients[i];
        nutrientsPerPiece.quantity += parseFloat(ingredientInfo.quantity);
      }

      var list = [];

      $scope.vars.data.ingredients.forEach(e => {
        if(e.info.ingredientsInfo) {
          const sfTot = e.info.ingredientsInfo.reduce((a, b) => a + b.quantity, 0);
          e.info.ingredientsInfo.forEach(i => i.quantity *= e.quantity / sfTot);

          if(e.info.ownRatio) {
            e.info.ingredientsInfo.forEach(i => i.ownRatio = e.info.ownRatio);
          }

          list = list.concat(e.info.ingredientsInfo);
        } else {
          list.push(e);
        }
      });

      for(i = 0; i < list.length; i++) {
        ingredientInfo = list[i];
        var quantity = parseFloat(ingredientInfo.quantity);
        var ingredient = ingredientInfo.info;
        var ownRatio = ingredientInfo.ownRatio || 1;
        var mult = (quantity / nutrientsPerPiece.quantity) *
          ($scope.vars.data.weightPerPiece / 100) *
          $scope.vars.nutrientsPer100g.ratio * ownRatio;

        nutrientsPerPiece.energyKcal += $scope.getValidValue(ingredient.energiaRicalcolataKcal * mult);
        nutrientsPerPiece.energyKJ += $scope.getValidValue(ingredient.energiaRicalcolataKJ * mult);
        nutrientsPerPiece.fats += $scope.getValidValue(ingredient.lipidiTotaliG * mult);
        nutrientsPerPiece.satFats += $scope.getValidValue(ingredient.lipidiAnimaliG * mult);
        nutrientsPerPiece.monoinsatFats += $scope.getValidValue(ingredient.grassiMonoinsaturiG * mult);
        nutrientsPerPiece.polyinsatFats += $scope.getValidValue(ingredient.grassiPolinsaturiG * mult);
        nutrientsPerPiece.carbohydrates += $scope.getValidValue(ingredient.carboidratiDisponibiliMSEG * mult);
        nutrientsPerPiece.sugarCarbs += $scope.getValidValue(ingredient.carboidratiSolubiliMSEG * mult);
        nutrientsPerPiece.polyol += $scope.getValidValue(ingredient.polioliG * mult);
        nutrientsPerPiece.starch += $scope.getValidValue(ingredient.amidoG * mult);
        nutrientsPerPiece.fibres += $scope.getValidValue(ingredient.fibraAlimentareTotaleG * mult);
        nutrientsPerPiece.proteins += $scope.getValidValue(ingredient.proteineTotaliG * mult);
        nutrientsPerPiece.salts += $scope.getValidValue(ingredient.sodioMg / 1000 * mult * 2.5);
      }

      $scope.normaliseNutritionalValues(nutrientsPerPiece, $scope.vars.data.weightPerPiece);
      $scope.vars.nutrientsPerPiece = nutrientsPerPiece;
    };

    $scope.normaliseNutritionalValues = (nvals, total) => {
      let nvalsTotal = 0;

      for(let nval in nvals) {
        if(nval !== 'energyKcal' && nval !== 'energyKJ' &&
            nval !== 'satFats' && nval !== 'monoinsatFats' &&
            nval !== 'polyinsatFats' && nval !== 'sugarCarbs' &&
            nval !== 'polyol' && nval !== 'starch' &&
            nval !== 'quantity' && nval !== 'ratio') {
          nvalsTotal += nvals[nval];
        }
      }

      if(nvalsTotal > total) {
        for(let nval in nvals) {
          if(nval !== 'energyKcal' && nval !== 'energyKJ' &&
              nval !== 'quantity' && nval !== 'ratio') {
            nvals[nval] = nvals[nval] / nvalsTotal * total;
          }
        }
      }
    };

    $scope.calculateGDAForPiece = function() {
      var gda = {
        energyKcal: 0,
        fats: 0,
        satFats: 0,
        carbohydrates: 0,
        sugarCarbs: 0,
        fibres: 0,
        proteins: 0,
        salts: 0
      };

      for(var prop in gda) {
        var perPiece = $scope.vars.nutrientsPerPiece[prop];
        var gdaInfo = $scope.vars.gdaInfo[prop];
        gda[prop] = (perPiece / gdaInfo) * 100;
      }

      $scope.vars.gda = gda;
      // console.log($scope.vars.gda);
    };

    $scope.toggleDatePickerState = function($event, dp) {
      $event.preventDefault();
      $event.stopPropagation();
      $scope.vars.data[dp] = !$scope.vars.data[dp];
    };

    $scope.getValidValue = function(n) {
      return $scope.isValidNumber(n) ? n : 0;
    };

    $scope.isValidNumber = function(n) {
      return !Number.isNaN(parseFloat(n)) && Number.isFinite(n);
    };

    $scope.convertTo2dp = function(n) {
      return n.toFixed(2);
    };

    $scope.editTraces = function() {
      Modal.traces.edit($scope.vars.data.traces, (traces) => {
        if(!$scope.vars.data.traces) { $scope.vars.data.traces = traces; }
        $scope.saveProduct();
      })();
    };

    $scope.notLastTrace = function(testTrace) {
      var lastTrace = '';

      for(var currentTrace in $scope.vars.data.traces) {
        if($scope.vars.data.traces[currentTrace]) {
          lastTrace = currentTrace;
        }
      }

      return testTrace !== lastTrace;
    };

    $scope.productChanged = function() {
      $scope.saveProduct();
    };

    $scope.manageAutomaticClaims = function() {
      var calorieClaimsInfo = {
        noLabel: 'Senza calorie',
        lowLabel: 'A basso contenuto calorico',
        quantityOf: 'energyKcal',
        noQuantity: 4,
        lowQuantity: 40
      };

      var fatClaimsInfo = {
        noLabel: 'Senza grassi',
        lowLabel: 'A basso contenuto di grassi',
        quantityOf: 'fats',
        noQuantity: 0.5,
        lowQuantity: 3
      };

      var sugarClaimsInfo = {
        noLabel: 'Senza zuccheri',
        lowLabel: 'A basso contenuto di zuccheri',
        quantityOf: 'sugarCarbs',
        noQuantity: 0.5,
        lowQuantity: 5
      };

      var fibreClaimsInfo = {
        highLabel: 'Ad alto contenuto di fibre',
        presenceLabel: 'Fonte di fibre',
        quantityOf: 'fibres',
        highQuantity: 6,
        presenceQuantity: 3
      };

      $scope.manageLowClaims(calorieClaimsInfo);
      $scope.manageLowClaims(fatClaimsInfo);
      $scope.manageLowClaims(sugarClaimsInfo);
      $scope.manageHighClaims(fibreClaimsInfo);
    };

    $scope.checkClaimIsPresent = function(claim) {
      var presentClaims = $scope.vars.data.claims;
      var filteredClaims = presentClaims.filter(e => e.label === claim);
      return filteredClaims.length ? filteredClaims[0] : false;
    };

    $scope.removeClaim = function(claim) {
      var presentClaims = $scope.vars.data.claims;
      var filteredClaims = presentClaims.filter(e => e.label !== claim);
      $scope.vars.data.claims = filteredClaims;
    };

    $scope.manageLowClaims = function(claimInfo) {
      var presentClaims = $scope.vars.data.claims;
      var noLabel = claimInfo.noLabel;
      var lowLabel = claimInfo.lowLabel;
      var nutrientsPer100g = $scope.vars.nutrientsPer100g;
      var noClaim = $scope.checkClaimIsPresent(noLabel);
      var lowClaim = $scope.checkClaimIsPresent(lowLabel);
      var claim = noClaim || lowClaim;
      var quantity = nutrientsPer100g[claimInfo.quantityOf];

      if(quantity <= claimInfo.lowQuantity) {
        var label = quantity <= claimInfo.noQuantity ? noLabel : lowLabel;

        if(claim) {
          claim.label = label;
        } else {
          presentClaims.push({ label: label, auto: true, enabled: false });
        }
      } else if(claim) {
        $scope.removeClaim(claim.label);
      }
    };

    $scope.manageHighClaims = function(claimInfo) {
      var presentClaims = $scope.vars.data.claims;
      var highLabel = claimInfo.highLabel;
      var presenceLabel = claimInfo.presenceLabel;
      var nutrientsPer100g = $scope.vars.nutrientsPer100g;
      var highClaim = $scope.checkClaimIsPresent(highLabel);
      var presenceClaim = $scope.checkClaimIsPresent(presenceLabel);
      var claim = highClaim || presenceClaim;
      var quantity = nutrientsPer100g[claimInfo.quantityOf];

      if(quantity >= claimInfo.presenceQuantity) {
        var label = quantity >= claimInfo.highQuantity ? highLabel : presenceLabel;

        if(claim) {
          claim.label = label;
        } else {
          presentClaims.push({ label: label, auto: true, enabled: false });
        }
      } else if(claim) {
        $scope.removeClaim(claim.label);
      }
    };

    $scope.addClaims = function() {
      Modal.new.claim(claim => {
        if(claim) {
          var presentClaims = $scope.vars.data.claims;
          presentClaims.push({ label: claim, auto: false, enabled: true });
          $scope.saveProduct();
        }
      })();
    };

    $scope.refreshProductInfo = function(enter) {
      $scope.calculateNutrientsFor100g();
      $scope.calculateNutrientsForPiece();
      $scope.calculateGDAForPiece();
      $scope.manageAutomaticClaims(enter);
      $scope.recalculateTotalPrice();
    };

    $scope.startsWith = function(ingredient, viewValue) {
      return ingredient.substr(0, viewValue.length).toLowerCase() === viewValue.toLowerCase();
    };

    $scope.changeWeightUnit = function(unit) {
      $scope.vars.data.weightUnit = unit;
      $scope.saveProduct();
    };

    $scope.changePieceType = function(type) {
      $scope.vars.data.weightPieceType = type;
      $scope.saveProduct();
    };

    $scope.getDateTypeFormat = function() {
      return $scope.vars.data.expDateSubType === 'day' ? 'dd-MM-yyyy' :
             $scope.vars.data.expDateSubType === 'month' ? 'MM-yyyy' : 'yyyy';
    };

    $scope.quantitySectionState = function() {
      var weight = $scope.vars.data.weight;
      var weightPerPiece = $scope.vars.data.weightPerPiece;
      return weight && weightPerPiece ?
        'complete' : weight || weightPerPiece ?
        'incomplete' : 'empty';
    };

    $scope.conservationSectionState = function() {
      var expType = $scope.vars.data.expType;

      if(expType === 'expTypeDate') {
        return $scope.vars.data.expDate ? 'complete' : 'empty';
      } else if(expType === 'expTypeTime') {
        var expTime = $scope.vars.data.expTime;
        var expTimeFrom = $scope.vars.data.expTimeFrom;
        var prodDate = $scope.vars.data.prodDate;
        var packDate = $scope.vars.data.packDate;

        if(expTimeFrom === 'expTimeFromProd') {
          return expTime && prodDate
            ? 'complete' : expTime || prodDate
            ? 'incomplete' : 'empty';
        } else if(expTimeFrom === 'expTimeFromPack') {
          return expTime && packDate
            ? 'complete' : expTime || packDate
            ? 'incomplete' : 'empty';
        }
      } else if(expType === 'expTypeTimeLen') {
        return $scope.vars.data.expTimeLen ? 'complete' : 'empty';
      }

      return 'empty';
    };

    $scope.originSectionState = function() {
      var lotto = $scope.vars.data.lotto;
      var manufacturer = $scope.vars.data.manufacturer;
      var packager = $scope.vars.data.packager;
      var manufacturingPlant = $scope.vars.data.manufacturingPlant;
      var countryOfOrigin = $scope.vars.data.countryOfOrigin;
      return lotto && manufacturer && packager && manufacturingPlant && countryOfOrigin ?
        'complete' : lotto || manufacturer || packager || manufacturingPlant || countryOfOrigin ?
        'incomplete' : 'empty';
    };

    $scope.recycleSectionState = function() {
      var material = $scope.vars.data.material;
      var highlightColor = $scope.vars.data.highlightColor;
      return material && highlightColor ?
        'complete' : material || highlightColor ?
        'incomplete' : 'empty';
    };

    $scope.getGdaColour = function(gda) {
      return gda <= 30 ? '#00C918' : gda <= 60 ? '#FFFF00' : '#FF6C3B';
    };

    $scope.weightPerPieceChanged = function() {
      $scope.calculateNutrientsForPiece();
      $scope.calculateGDAForPiece();
      $scope.productChanged();
    };

    $scope.totalWeightPostPrepChanged = () => {
      $scope.refreshProductInfo();
      $scope.saveProduct();
      $scope.refreshQuidTables();
    };

    $scope.getVariationString = () => {
      const variation = (1 / $scope.vars.nutrientsPer100g.ratio) * 100 - 100;
      const increase = variation > 0;

      if(!$scope.vars.data.totalWeightPostPrep ||
        $scope.vars.data.totalWeightPostPrep === 0) {
        return '-';
      } else if(variation === 0) {
        return 'Nessuna variazione';
      } else if(increase) {
        return 'Aumento del ' + variation.toFixed(2) + '% di peso';
      } else {
        return 'Calo del ' + (-variation).toFixed(2) + '% di peso';
      }
    };

    $scope.getIngredients = function(prefix) {
      return $scope.searchIngredientsInDb(prefix);
    };

    $scope.searchIngredientsInDb = prefix => {
      const userEmail = Auth.getCurrentUser().email;
      const noExtIngredients = $scope.vars.noExternalIngredientsInSearch;
      const favs = $scope.vars.searchFavsOnly ? $scope.vars.favoriteIngredients : [];
      const recipesOnly = $scope.vars.searchOwnOnly;
      const language = $scope.vars.data.multilingual ? $scope.vars.language : '';
      const data = {
        noExtIngredients: noExtIngredients,
        favs: favs,
        recipesOnly: recipesOnly,
        language: language
      };
      return $http.post('/api/alimenti/' + prefix + '/' + userEmail, data).then(res => {
        $scope.vars.searchList = $scope.addIngredientLabels(res.data);
        $scope.vars.searchList.sort((a, b) => {
          const title = 'titolo' + $scope.langToCod(language);
          if(a[title] < b[title]) { return -1; }
          if(a[title] > b[title]) { return 1; }
          return 0;
        });

        return $scope.vars.searchList.filter(e => e.recipeId !== parseInt($scope.vars.id));
      });
    };

    $scope.addIngredientLabels = ingredients => {
      return ingredients.map(i => {
        const name = $scope.getIngredientName(i);
        const type = $scope.getIngredientType(i);
        const category = $scope.getIngredientCategory(i);
        const source = $scope.getIngredientSource(i);
        i.label = name + type + category + source;

        return i;
      });
    };

    $scope.getIngredientName = ingredient => {
      const language = $scope.vars.data.multilingual ? $scope.vars.language : '';
      const nameField = 'titolo' + $scope.langToCod(language);
      return ingredient[nameField];
    };

    $scope.getIngredientType = ingredient => {
      let type = '';

      if(ingredient.id >= 1000 && !ingredient.multilingual) {
        type = ', Tipologia: ';

        if(ingredient.tipo) {
          type += $scope.getTypeLabel(ingredient.tipo);
        } else {
          type += $scope.getTypeLabel(
            ingredient.ingredienti.length ? 'Semilavorato' : 'Ingrediente');
        }
      }

      return type;
    };

    $scope.getTypeLabel = type => {
      switch(type) {
        case 'Ingrediente': return 'Ingrediente singolo';
        case 'Semilavorato': return 'Prodotto semilavorato / composto';
        case 'Aroma': return 'Aroma';
        case 'Additivo': return 'Additivo Alimentare';
      }
    };

    $scope.getIngredientCategory = ingredient => {
      // const language = $scope.vars.data.multilingual ? $scope.vars.language : '';
      // const categoryField = 'categoria' + $scope.langToCod(language);
      // return ingredient[categoryField] ? (', Categoria: ' + ingredient[categoryField]) : '';
      const category = ingredient.categoria || ingredient.categoriaI || '';
      return category ? (', Categoria: ' + category) : '';
    };

    $scope.getIngredientSource = ingredient => {
      const inran = 'Unità di Chimica degli Alimenti dell’INRAN e/o accurata selezione bibliografica prevalentemente italiana';
      const usav = 'Ufficio federale della sicurezza alimentare e di veterinaria Svizzero USAV';
      const source = ingredient.source ? ingredient.source :
        ingredient.id < 1000 ? inran :
        ingredient.multilingual ? usav :
        ingredient.recipe ? 'Ricetta propria' : 'Scheda tecnica del prodotto';
      return ', Fonte dei dati: ' + source;
    };

    /**************************************************************************/

    $scope.manageSemifinishedDisplayName = semifinished => {
      const ingredientsString = semifinished.info.ingredients
        .map(e => e.trim()).join(', ');

      const total = $scope.vars.nutrientsPer100g.quantity;
      let semifinishedString = ingredientsString;

      if((semifinished.quantity * 100 / total) > 2) {
        semifinishedString = semifinished.name + ' (' + semifinishedString + ')';
      }

      return semifinishedString;
    };

    $scope.manageAromaDisplayName = aroma => {
      const ingredientsString = aroma.info.ingredients
        .map(e => e.trim()).join(', ');
      const othersString = aroma.info.aromaExtras
        .map(e => e.trim()).join(', ');
      const aromaString = aroma.info.aromaDeclarationInfo + ' - ' + aroma.name +
        ' (' + ingredientsString + ', ' + othersString + ')';
      return aromaString;
    };

    $scope.resetDisplayName = e => {
      if(e.multilingual) {
        const nameField = 'name' + $scope.langToCod($scope.vars.language);
        return e[nameField].replace(/"/g, '\'').replace(',', ' ');
      } else {
        const type =
          e.info.type || (e.info.ingredients.length ? 'Semilavorato' : '');
        return (type === 'Aroma' ? $scope.manageAromaDisplayName(e) :
          type === 'Semilavorato' ? $scope.manageSemifinishedDisplayName(e) :
          e.name).replace(/"/g, '\'');
      }
    };

    $scope.toggleSubView = (subview, multilingualLabel) => {
      $scope.vars[subview] = !$scope.vars[subview];

      if($scope.vars[subview]) {
        $scope.vars.multilingualLabel = multilingualLabel;

        if(subview !== 'ingredientsBookGen') {
          $scope.vars.data.ingredients.forEach(e => {
            if(!e.displayName || e.multilingual) {
              e.displayName = $scope.resetDisplayName(e);
            }
          });

          if(subview === 'labelGen') {
            $scope.initLabelEditor();
          }

          if(subview === 'techSheetGen') {
            $scope.setupTechSheetView();
          }
        } else {
          $scope.setupIngredientsBookView();
        }
      }
    };

    $scope.setupTechSheetView = () => {
      if(!$scope.vars.data.allergens) {
        $scope.vars.data.allergens = {
          sedano: false,
          crostacei: false,
          uova: false,
          pesce: false,
          glutine: false,
          lupini: false,
          latte: false,
          molluschi: false,
          senape: false,
          fruttiAGuscioNoci: false,
          arachidi: false,
          semiDiSesamo: false,
          soia: false,
          anidrideSolfosaSolfiti: false
        };

        $scope.saveProduct();
      }

      // TODO: auto allergens
    };

    $scope.setupIngredientsBookView = () => {
      const list = $scope.ingredientsBookVars.recipes.map(e => {
        return { name: e.name, _id: e._id };
      });

      $http.post('/api/products/list/' + Auth.getCurrentUser()._id, list).then(res => {
        $scope.ingredientsBookVars.recipesFull = res.data;
        $scope.ingredientsBookVars.recipesIngredientsTableConfigs = [];
        $scope.ingredientsBookVars.recipesQuidsTableConfigs = [];
        const current = $scope.ingredientsBookVars.recipesFull.findIndex(e =>
          e.name === $scope.vars.data.name);

        if(current >= 0) {
          $scope.ingredientsBookVars.recipesFull[current] = $scope.vars.data;
        }

        $scope.ingredientsBookVars.recipesFull.forEach(r => {
          r.ingredients.forEach(e => {
            if(!e.displayName || e.multilingual) {
              e.displayName = $scope.resetDisplayName(e);
            }
          });

          const tableConfig = $scope.createRecipeIngredientsTableConfig(r.ingredients);
          $scope.ingredientsBookVars.recipesIngredientsTableConfigs.push(tableConfig);

          const quidTableConfig = $scope.createRecipeQuidsTableConfig(r);
          $scope.ingredientsBookVars.recipesQuidsTableConfigs.push(quidTableConfig);

          $('#recipesTable-' + r._id).on('editable-save.bs.table', function($el, field, row) {
            const elem = r.ingredients.find(e => e.id === row.id);
            if(elem) { elem.displayName = row.displayName.trim(); }

            $http.put('/api/products/' + r._id, r).then(() => {
              // saved
            }).catch(err => {
              console.log(err);
            });
          });

          $('#recipesTable-' + r._id).on('check.bs.table', function(e, row, $el) {
            const elem = r.ingredients.find(e => e.id === row.id);
            if(elem) { elem.visible = true; }

            $http.put('/api/products/' + r._id, r).then(() => {
              // saved
            }).catch(err => {
              console.log(err);
            });
          });

          $('#recipesTable-' + r._id).on('uncheck.bs.table', function(e, row, $el) {
            const elem = r.ingredients.find(e => e.id === row.id);
            if(elem) { elem.visible = false; }

            $http.put('/api/products/' + r._id, r).then(() => {
              // saved
            }).catch(err => {
              console.log(err);
            });
          });

          const $quidsTable = $('#quidsTable-' + r._id);

          $quidsTable.on('editable-save.bs.table', function($el, field, row) {
            const elem = r.ingredients.find(e => e.id === row.id);
            if(elem) { elem.quidWording = row.quidWording; }

            $http.put('/api/products/' + r._id, r).then(() => {
              // saved
            }).catch(err => {
              console.log(err);
            });
          });

          $scope.resetQuidTableState($quidsTable, r);

          $quidsTable.on('reset-view.bs.table', function(data) {
            $scope.resetQuidTableState($quidsTable, r);
          });
        });
      });
    };

    /**************************************************************************/
    /**************************************************************************/

    $scope.labelOptions = {
      paperSizes: {
        fixed: [
          { w: 60, h: 65 },
          { w: 60, h: 100 },
          { w: 60, h: 110 },
          { w: 65, h: 90 },
          { w: 80, h: 100 },
          { w: 100, h: 60 },
          { w: 100, h: 80 },
          { w: 100, h: 100 },
          { w: 100, h: 150 },
          { w: 149, h: 210 },
          { w: 150, h: 213 }
        ],
        fluid: [
          { w: 210, h: 297, name: 'A4 -' },
          { w: 148, h: 210, name: 'A5 -' }
        ]
      },
      labelSizes: {
        fixed: [
          { v: 1 , l: 'XL' }
        ],
        fluid: [
          { v: 1 , l: 'XL' },
          { v: 2 , l: 'L' },
          { v: 3 , l: 'M' },
          { v: 4 , l: 'S' }
        ]
      }
    };

    $scope.labelParams = {
      paperSizes: [],
      labelSizes: [],
      allergenStyles: [
        { style: 'bold', label: 'Grassetto' },
        { style: 'bold-underlined', label: 'Grassetto e sottolineato' }
      ]
    };

    $scope.labelVars = {
      new: true,
      logoFile: {
        id: 'logo-img',
        label: 'Intestazione aziendale (formato PNG, JPG/JPEG o PDF)',
        value: '',
        userId: Auth.getCurrentUser()._id,
        preview: true
      },
      simpleIngredientsTitle: 'true',
      manufacturingPlantLab: 'stabilimento'
    };

    $scope.loadLabelSettings = () => {
      if($scope.vars.data.labelSettings) {
        $scope.labelGenPaperTypeChange($scope.vars.data.labelSettings.paperType);
        $scope.labelVars = $scope.vars.data.labelSettings;
        $scope.updateViewWithLabelSettings($scope.vars.data.labelSettings);
        return;
      }

      const user = Auth.getCurrentUser()._id;
      $http.get('/api/label-settings/' + user).then((res) => {
        if(res.data) {
          $scope.labelGenPaperTypeChange(res.data.settings.paperType);
          $scope.labelVars = res.data.settings;
          $scope.updateViewWithLabelSettings(res.data.settings);
        }
      });
    };

    $scope.updateViewWithLabelSettings = (settings) => {
      if($scope.labelParams.paperSizes) {
        $scope.labelVars.paperSize = $scope.labelParams.paperSizes.find(e =>
          e.w === settings.paperSize.w &&
          e.h === settings.paperSize.h);
      }

      if($scope.labelParams.labelSizes) {
        $scope.labelVars.labelSize = $scope.labelParams.labelSizes.find(e =>
          e.v === settings.labelSize.v &&
          e.l === settings.labelSize.l);
      }

      if($scope.labelVars.expTime) {
        if(($scope.vars.data.expType === 'expTypeDate' && !$scope.vars.data.expDate) ||
          ($scope.vars.data.expType === 'expTypeTime' && !$scope.vars.data.expTime)) {
            $scope.vars.data.expType = 'expTypeTime';
            $scope.vars.data.expTime = $scope.labelVars.expTime;
            $scope.vars.data.expTimeType = $scope.labelVars.expTimeType;
            $scope.vars.data.expTimeFrom = $scope.labelVars.expTimeFrom;
            $scope.vars.data.expDateType = $scope.labelVars.expDateType;
            $scope.vars.data.expDateSubType = $scope.labelVars.expDateSubType;
        }
      }

      // TODO: implement save & load for label editor
    };

    $scope.saveLabelSettings = () => {
      const user = Auth.getCurrentUser()._id;
      const settings = { settings: $scope.labelVars };
      if(settings.settings.new) {
        settings.settings.new = false;
        settings._id = user;
        $http.post('/api/label-settings/', settings).then(() => {
          alert('Impostazioni salvate');
        }).catch(() => {
          settings.settings.new = true;
          alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
        });
      } else {
        $http.put('/api/label-settings/' + user, settings).then(() => {
          alert('Impostazioni salvate');
        }).catch(() => {
          alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
        });
      }
    };

    $scope.saveLabelSettingsRecipe = () => {
      $scope.vars.data.labelSettings = $scope.labelVars;
      $scope.saveProduct();
      alert('Impostazioni salvate');
    };

    $scope.deleteLabelSettingsRecipe = () => {
      $scope.vars.data.labelSettings = undefined;
      $scope.saveProduct();
      $scope.loadLabelSettings();
      alert('Impostazioni rimosse');
    };

    $scope.labelGenPaperTypeChange = (extPaperType) => {
      const paperType = extPaperType || $scope.labelVars.paperType;
      $scope.labelParams.paperSizes = $scope.labelOptions.paperSizes[paperType];
      $scope.labelVars.paperSize = {};
      $scope.labelParams.labelSizes = $scope.labelOptions.labelSizes[paperType];
      $scope.labelVars.labelSize = {};
    };

    $scope.labelGenLabelTypeChange = () => {

    };

    $scope.labelGenParamsCheck = () => {
      if($scope.vars.multilingualLabel &&
        !$scope.labelVars.italian && !$scope.labelVars.english &&
        !$scope.labelVars.german && !$scope.labelVars.french) {
        return alert('Selezionare almeno una lingua');
      }

      if(!$scope.labelVars.paperType) {
        return alert('Selezionare tipo foglio');
      }

      if(!$scope.labelVars.paperSize.w) {
        return alert('Selezionare dimensione foglio');
      }

      if(!$scope.labelVars.labelType) {
        return alert('Selezionare tipo etichetta');
      }

      if($scope.labelVars.labelSize && isNaN($scope.labelVars.labelSize.v)) {
        return alert('Selezionare dimensione etichetta');
      }

      if(($scope.labelVars.labelType === "ingredients-list" ||
          $scope.labelVars.ingredients) && !$scope.labelVars.allergenStyle) {
        return alert('Selezionare stile evidenziazione allergeni');
      }

      if(($scope.labelVars.labelType === "ingredients-list" ||
          $scope.labelVars.ingredients) &&
          !$scope.labelVars.simpleIngredientsTitle) {
        return alert('Selezionare dicitura ingredienti / i nostri ingredienti');
      }

      if($scope.labelVars.logo && !$scope.labelVars.logoFile.value) {
        return alert('Caricare intestazione aziendale');
      }

      if($scope.labelVars.qrcode && !$scope.labelVars.qrcodeLink) {
        return alert('Inserire link QR code');
      }

      if($scope.labelVars.manufacturingPlant &&
        !$scope.labelVars.manufacturingPlantLab) {
        return alert('Selezionare dicitura stabilimento / laboratorio');
      }

      return true;
    };

    $scope.labelGenGenerateLabelData = () => {
      if(!$scope.labelGenParamsCheck()) {
        return;
      }

      const data = {
        multilingualLabel: $scope.vars.multilingualLabel,
        Italiano: $scope.vars.multilingualLabel && $scope.labelVars.italian,
        Inglese: $scope.vars.multilingualLabel && $scope.labelVars.english,
        Tedesco: $scope.vars.multilingualLabel && $scope.labelVars.german,
        Francese: $scope.vars.multilingualLabel && $scope.labelVars.french
      };

      data.name = $scope.vars.data.name;

      if($scope.labelVars.description) {
        data.description = $scope.vars.data.description;
      }

      data.logo = $scope.labelVars.logo;

      if(data.logo) {
        data.logoFile = $scope.labelVars.logoFile.uniqueIdentifier;
        data.logoFileInfo = $scope.labelVars.logoFile;
      }

      data.qrcode = $scope.labelVars.qrcode;

      if(data.qrcode) {
        data.qrcodeLink = $scope.labelVars.qrcodeLink;
      }

      if($scope.vars.barcodePreviewState==='display') {
        data.barcode = $scope.labelVars.barcode;
      }

      if(data.barcode) {
        data.barcodeData = $scope.vars.data.barcodeData;
        data.barcodeType = $scope.vars.data.barcodeType;
      }

      if($scope.labelVars.labelType === 'nutritional-values-table') {
        data.nutritionalValues = {
          for100g: $scope.vars.nutrientsPer100g
        };

        if($scope.labelVars.forUnit) {
          data.nutritionalValues.showForUnit = true;
          data.nutritionalValues.forUnit = $scope.vars.nutrientsPerPiece;
          data.nutritionalValues.unitWeight = $scope.vars.data.weightPerPiece;
          data.nutritionalValues.unitType = $scope.vars.data.weightPieceType;
        }

        if($scope.labelVars.gda) {
          data.nutritionalValues.showGda = true;
          data.nutritionalValues.gda = $scope.vars.gda;
        }

        data.nutritionalValues.monoinsatFats = $scope.labelVars.monoinsatFats;
        data.nutritionalValues.polyinsatFats = $scope.labelVars.polyinsatFats;
        data.nutritionalValues.polyols = $scope.labelVars.polyols;
        data.nutritionalValues.starch = $scope.labelVars.starch;
        data.nutritionalValues.fibres = $scope.labelVars.fibres;
        data.nutritionalValues.colNutritionalVals = $scope.labelVars.colNutritionalVals;
      }
      else if($scope.labelVars.labelType === "nutritional-values-text") {
        data.nutritionalValuesTextInfo = {
          for100g: $scope.vars.nutrientsPer100g
        };

        if($scope.labelVars.forUnit) {
          data.nutritionalValuesTextInfo.showForUnit = true;
          data.nutritionalValuesTextInfo.forUnit = $scope.vars.nutrientsPerPiece;
          data.nutritionalValuesTextInfo.unitWeight = $scope.vars.data.weightPerPiece;
          data.nutritionalValuesTextInfo.unitType = $scope.vars.data.weightPieceType;
        }

        if($scope.labelVars.gda) {
          data.nutritionalValuesTextInfo.showGda = true;
          data.nutritionalValuesTextInfo.gda = $scope.vars.gda;
        }

        data.nutritionalValuesTextInfo.monoinsatFats = $scope.labelVars.monoinsatFats;
        data.nutritionalValuesTextInfo.polyinsatFats = $scope.labelVars.polyinsatFats;
        data.nutritionalValuesTextInfo.polyols = $scope.labelVars.polyols;
        data.nutritionalValuesTextInfo.starch = $scope.labelVars.starch;
        data.nutritionalValuesTextInfo.fibres = $scope.labelVars.fibres;
      }

      if($scope.labelVars.labelType === "ingredients-list" ||
          $scope.labelVars.ingredients) {
        data.simpleIngredientsTitle =
          $scope.labelVars.simpleIngredientsTitle === 'true';
        data.ingredients = $scope.vars.data.ingredients;
        data.ingredientPercs = $scope.vars.data.ingredientPercs;
        data.totalWeightPostPrep = $scope.vars.data.totalWeightPostPrep;
        data.allergenStyle = $scope.labelVars.allergenStyle;
        data.traces = $scope.vars.data.traces;
        data.traceLabels = $scope.vars.traceLabels;
      }

      if($scope.labelVars.recipeAdvice) {
        data.recipeAdvice = $scope.vars.data.notes;
      }

      if($scope.labelVars.claims) {
        data.claims = $scope.vars.data.claims;
      }

      if($scope.labelVars.weight) {
        data.isWeight = true;
        data.weight = $scope.vars.data.weight;
        data.weightUnit = $scope.vars.data.weightUnit;
        data.weightE = $scope.labelVars.weightE;
      }

      if($scope.labelVars.prodDate) {
        data.prodDate = $scope.vars.data.prodDate;
      }

      if($scope.labelVars.packDate) {
        data.packDate = $scope.vars.data.packDate;
      }

      if($scope.labelVars.expDate) {
        data.expDateType = $scope.vars.data.expDateType;
        data.expDateSubType = $scope.vars.data.expDateSubType;

        if($scope.vars.data.expType === 'expTypeDate') {
          data.expDate = $scope.vars.data.expDate;
        } else if($scope.vars.data.expType === 'expTypeTime') {
          const from = $scope.vars.data.expTimeFrom === 'expTimeFromProd' ?
            $scope.vars.data.prodDate : $scope.vars.data.packDate;

          if(from) {
            data.expDate = moment(from)
              .add($scope.vars.data.expTime, $scope.vars.data.expTimeType);
          }
        } else if($scope.vars.data.expType === 'expTypeTimeLen') {
          data.expTimeLen = $scope.vars.data.expTimeLen + " " +
            $scope.expTypeTimeToIt($scope.vars.data.expTimeType).toLowerCase();
        }
      }

      if($scope.labelVars.conservationInfo) {
        data.conservationInfo = $scope.vars.data.conservationInfo;
      }

      if($scope.labelVars.lotto) {
        data.lotto = $scope.vars.data.lotto;
      }

      if($scope.labelVars.manufacturer) {
        data.manufacturer = $scope.vars.data.manufacturer;
      }

      if($scope.labelVars.packager) {
        data.packager = $scope.vars.data.packager;
      }

      if($scope.labelVars.manufacturingPlant) {
        data.manufacturingPlant = $scope.vars.data.manufacturingPlant;
        data.manufacturingPlantLab = $scope.labelVars.manufacturingPlantLab;
      }

      if($scope.labelVars.countryOfOrigin) {
        data.countryOfOrigin = $scope.vars.data.countryOfOrigin;
      }

      if($scope.labelVars.recycleInfo) {
        data.material = $scope.getTranslatedMaterial($scope.vars.data.material);
        data.material2 = $scope.getTranslatedMaterial($scope.vars.data.material2);
        data.material3 = $scope.getTranslatedMaterial($scope.vars.data.material3);
        data.highlightColor = $scope.vars.data.highlightColor;
        data.highlightColor2 = $scope.vars.data.highlightColor2;
        data.highlightColor3 = $scope.vars.data.highlightColor3;
      }

      if($scope.labelVars.paperSize.w === 210 && $scope.labelVars.paperSize.h === 297) {
        let onlyNutVals = true;

        for(let prop in data) {
          if(data[prop] && prop !== 'nutritionalValues' &&
            prop !== 'nutritionalValuesTextInfo') {
              onlyNutVals = false;
              break;
          }
        }

        data.onlyNutVals = onlyNutVals;
      }

      data.multilingual = $scope.vars.data.multilingual;
      data.language = $scope.vars.language;

      const labelData = {
        base: $scope.labelVars.paperType,
        params: {
          layout: {
            page: {
              width: $scope.labelVars.paperSize.w,
              height: $scope.labelVars.paperSize.h
            },
            cols: $scope.labelVars.labelSize.v,
            border: $scope.labelVars.border
          },
          data: data
        }
      };

      $scope.labelGenGenerateLabel(labelData);
    };

    $scope.labelGenGenerateLabel = data => {
      // console.log(data);
      $scope.vars.loadAnimation = true;
      const user = Auth.getCurrentUser()._id;
      const recipe = $scope.vars.data._id;
      const url = '/api/label-generator/generate/' + user;
      const config = { responseType: 'arraybuffer' };

      $http.post(url, JSON.stringify(data), config).then(res => {
        var file = new Blob([res.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        $scope.vars.loadAnimation = false;
      }).catch(err => {
        console.log(err);
        $scope.vars.loadAnimation = false;
        alert('Errore durante la generazione dell\'etichetta');
      });
    };

    /**************************************************************************/
    /**************************************************************************/

    $scope.techSheetParams = {
      paperSizes: [
        { w: 210, h: 297, name: 'A4 -' },
        { w: 148, h: 210, name: 'A5 -' }
      ],
      allergenStyles: [
        { style: 'bold', label: 'Grassetto' },
        { style: 'bold-underlined', label: 'Grassetto e sottolineato' }
      ]
    };

    $scope.techSheetVars = {
      new: true,
      logoFile: {
        id: 'logo-img',
        label: 'Intestazione aziendale (formato PNG, JPG/JPEG o PDF)',
        value: '',
        userId: Auth.getCurrentUser()._id,
        preview: true
      },
      // productImgFile: {
      //   id: 'product-img',
      //   label: 'Immagine del prodotto (formato PNG, JPG/JPEG o PDF)',
      //   value: '',
      //   userId: Auth.getCurrentUser()._id,
      //   preview: true
      // },
      simpleIngredientsTitle: 'true',
      manufacturingPlantLab: 'stabilimento'
    };

    $scope.loadTechSheetSettings = () => {
      if($scope.vars.data.techSheetSettings) {
        $scope.techSheetVars = $scope.vars.data.techSheetSettings;
        $scope.updateViewWithTechSheetSettings($scope.vars.data.techSheetSettings);
        return;
      }

      const user = Auth.getCurrentUser()._id;
      $http.get('/api/techSheet-settings/' + user).then((res) => {
        if(res.data) {
          $scope.techSheetVars = res.data.settings;
          $scope.updateViewWithTechSheetSettings(res.data.settings);
        }
      });
    };

    $scope.updateViewWithTechSheetSettings = (settings) => {
      if($scope.techSheetParams.paperSizes) {
        $scope.techSheetVars.paperSize = $scope.techSheetParams.paperSizes.find(e =>
          e.w === settings.paperSize.w &&
          e.h === settings.paperSize.h);
      }
    };

    $scope.saveTechSheetSettings = () => {
      const user = Auth.getCurrentUser()._id;
      const settings = { settings: $scope.techSheetVars };
      if(settings.settings.new) {
        settings.settings.new = false;
        settings._id = user;
        $http.post('/api/techSheet-settings/', settings).then(() => {
          alert('Impostazioni salvate');
        }).catch(() => {
          settings.settings.new = true;
          alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
        });
      } else {
        $http.put('/api/techSheet-settings/' + user, settings).then(() => {
          alert('Impostazioni salvate');
        }).catch(() => {
          alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
        });
      }
    };

    $scope.saveTechSheetSettingsRecipe = () => {
      $scope.vars.data.techSheetSettings = $scope.techSheetVars;
      $scope.saveProduct();
      alert('Impostazioni salvate');
    };

    $scope.deleteTechSheetSettingsRecipe = () => {
      $scope.vars.data.techSheetSettings = undefined;
      $scope.saveProduct();
      $scope.loadTechSheetSettings();
      alert('Impostazioni rimosse');
    };

    $scope.techSheetGenGenerateTechSheetData = () => {
      if(!$scope.techSheetVars.paperSize.w) {
        return alert('Selezionare dimensione foglio');
      }

      if(!$scope.techSheetVars.techSheetType) {
        return alert('Selezionare tipo etichetta');
      }

      if($scope.techSheetVars.isAppDate && !$scope.techSheetVars.appDate) {
        return alert('Selezionare data di approvazione');
      }

      if($scope.techSheetVars.isRevDate && !$scope.techSheetVars.revDate) {
        return alert('Selezionare data di revisione');
      }

      if($scope.techSheetVars.ingredients &&
        !$scope.techSheetVars.allergenStyle) {
        return alert('Selezionare stile evidenziazione allergeni');
      }

      if(($scope.techSheetVars.ingredients) &&
          !$scope.techSheetVars.simpleIngredientsTitle) {
        return alert('Selezionare dicitura ingredienti / i nostri ingredienti');
      }

      if($scope.techSheetVars.logo && !$scope.techSheetVars.logoFile.value) {
        return alert('Caricare intestazione aziendale');
      }

      if($scope.techSheetVars.productImg &&
        !($scope.vars.data.productImgFile && $scope.vars.data.productImgFile.value)) {
        return alert('Caricare immagine prodotto');
      }

      if($scope.techSheetVars.qrcode && !$scope.techSheetVars.qrcodeLink) {
        return alert('Inserire link QR code');
      }

      if($scope.techSheetVars.manufacturingPlant &&
        !$scope.techSheetVars.manufacturingPlantLab) {
        return alert('Selezionare dicitura stabilimento / laboratorio');
      }

      const data = { isTechSheet: true };

      if($scope.techSheetVars.isAppDate) {
        data.appDate = $scope.techSheetVars.appDate;
      }

      if($scope.techSheetVars.isRevDate) {
        data.revDate = $scope.techSheetVars.revDate;
      }

      data.name = $scope.vars.data.name;

      data.logo = $scope.techSheetVars.logo;

      if(data.logo) {
        data.logoFile = $scope.techSheetVars.logoFile.uniqueIdentifier;
        data.logoFileInfo = $scope.techSheetVars.logoFile;
      }

      data.productImg = $scope.techSheetVars.productImg;

      if(data.productImg) {
        data.productImgFile = $scope.vars.data.productImgFile.uniqueIdentifier;
        data.productImgFileInfo = $scope.vars.data.productImgFile;
      }

      data.qrcode = $scope.techSheetVars.qrcode;

      if(data.qrcode) {
        data.qrcodeLink = $scope.techSheetVars.qrcodeLink;
      }

      if($scope.vars.barcodePreviewState==='display') {
        data.barcode = $scope.techSheetVars.barcode;
      }

      if(data.barcode) {
        data.barcodeData = $scope.vars.data.barcodeData;
        data.barcodeType = $scope.vars.data.barcodeType;
      }

      if($scope.techSheetVars.techSheetType === "nutritional-values-table") {
        data.nutritionalValues = {
          for100g: $scope.vars.nutrientsPer100g
        };

        if($scope.techSheetVars.forUnit) {
          data.nutritionalValues.showForUnit = true;
          data.nutritionalValues.forUnit = $scope.vars.nutrientsPerPiece;
          data.nutritionalValues.unitWeight = $scope.vars.data.weightPerPiece;
          data.nutritionalValues.unitType =
            $scope.getTranslatedUnitType($scope.vars.data.weightPieceType);
        }

        if($scope.techSheetVars.gda) {
          data.nutritionalValues.showGda = true;
          data.nutritionalValues.gda = $scope.vars.gda;
        }

        data.nutritionalValues.monoinsatFats = $scope.techSheetVars.monoinsatFats;
        data.nutritionalValues.polyinsatFats = $scope.techSheetVars.polyinsatFats;
        data.nutritionalValues.polyols = $scope.techSheetVars.polyols;
        data.nutritionalValues.starch = $scope.techSheetVars.starch;
        data.nutritionalValues.fibres = $scope.techSheetVars.fibres;
        data.nutritionalValues.colNutritionalVals = $scope.techSheetVars.colNutritionalVals;
      }
      else if($scope.techSheetVars.techSheetType === "nutritional-values-text") {
        data.nutritionalValuesTextInfo = {
          for100g: $scope.vars.nutrientsPer100g
        };

        if($scope.labelVars.forUnit) {
          data.nutritionalValuesTextInfo.showForUnit = true;
          data.nutritionalValuesTextInfo.forUnit = $scope.vars.nutrientsPerPiece;
          data.nutritionalValuesTextInfo.unitWeight = $scope.vars.data.weightPerPiece;
          data.nutritionalValuesTextInfo.unitType = $scope.vars.data.weightPieceType;
        }

        if($scope.labelVars.gda) {
          data.nutritionalValuesTextInfo.showGda = true;
          data.nutritionalValuesTextInfo.gda = $scope.vars.gda;
        }

        data.nutritionalValuesTextInfo.monoinsatFats = $scope.techSheetVars.monoinsatFats;
        data.nutritionalValuesTextInfo.polyinsatFats = $scope.techSheetVars.polyinsatFats;
        data.nutritionalValuesTextInfo.polyols = $scope.techSheetVars.polyols;
        data.nutritionalValuesTextInfo.starch = $scope.techSheetVars.starch;
        data.nutritionalValuesTextInfo.fibres = $scope.techSheetVars.fibres;
      }

      if($scope.techSheetVars.ingredients) {
        data.simpleIngredientsTitle = $scope.techSheetVars.simpleIngredientsTitle === 'true';
        data.ingredients = $scope.vars.data.ingredients;
        data.ingredientPercs = $scope.vars.data.ingredientPercs;
        data.totalWeightPostPrep = $scope.vars.data.totalWeightPostPrep;
        data.allergenStyle = $scope.techSheetVars.allergenStyle;
        data.allergens = $scope.vars.data.allergens;
        data.traces = $scope.vars.data.traces;
        data.traceLabels = $scope.vars.traceLabels;
      }

      if($scope.techSheetVars.recipeAdvice) {
        data.recipeAdvice = $scope.vars.data.notes;
      }

      if($scope.techSheetVars.claims) {
        data.claims = $scope.vars.data.claims;
      }

      if($scope.techSheetVars.conservationInfo) {
        data.conservationInfo = $scope.vars.data.conservationInfo;
      }

      if($scope.techSheetVars.lotto) {
        data.lotto = $scope.vars.data.lotto;
      }

      if($scope.techSheetVars.manufacturer) {
        data.manufacturer = $scope.vars.data.manufacturer;
      }

      if($scope.techSheetVars.packager) {
        data.packager = $scope.vars.data.packager;
      }

      if($scope.techSheetVars.manufacturingPlant) {
        data.manufacturingPlant = $scope.vars.data.manufacturingPlant;
        data.manufacturingPlantLab = $scope.techSheetVars.manufacturingPlantLab;
      }

      if($scope.techSheetVars.countryOfOrigin) {
        data.countryOfOrigin = $scope.vars.data.countryOfOrigin;
      }

      if($scope.techSheetVars.recycleInfo) {
        data.material = $scope.getTranslatedMaterial($scope.vars.data.material);
        data.material2 = $scope.getTranslatedMaterial($scope.vars.data.material2);
        data.material3 = $scope.getTranslatedMaterial($scope.vars.data.material3);
        data.highlightColor = $scope.vars.data.highlightColor;
        data.highlightColor2 = $scope.vars.data.highlightColor2;
        data.highlightColor3 = $scope.vars.data.highlightColor3;
      }

      if($scope.techSheetVars.productCode) {
        data.productCode = $scope.vars.data.productCode;
      }

      if($scope.techSheetVars.shelfLife) {
        data.shelfLife = $scope.vars.data.shelfLife;
      }

      if($scope.techSheetVars.detailedDescription) {
        data.detailedDescription = $scope.vars.data.detailedDescription;
      }

      if($scope.techSheetVars.composition) {
        data.composition = $scope.vars.data.composition;
      }

      if($scope.techSheetVars.characteristicsPhys) {
        data.characteristicsPhys = $scope.vars.data.characteristicsPhys;
      }

      if($scope.techSheetVars.characteristicsMB) {
        data.characteristicsMB = $scope.vars.data.characteristicsMB;
      }

      if($scope.techSheetVars.packageMaterial) {
        data.packageMaterial = $scope.vars.data.packageMaterial;
      }

      if($scope.techSheetVars.packageWeightAndDim) {
        data.packageWeightAndDim = $scope.vars.data.packageWeightAndDim;
      }

      if($scope.techSheetVars.usage) {
        data.usage = $scope.vars.data.usage;
      }

      if($scope.techSheetVars.conformityDec) {
        data.conformityDec = $scope.vars.data.conformityDec;
      }

      data.multilingual = $scope.vars.data.multilingual;
      data.language = $scope.vars.language;

      const techSheetData = {
        base: 'fixed',
        params: {
          layout: {
            page: {
              width: $scope.techSheetVars.paperSize.w,
              height: $scope.techSheetVars.paperSize.h
            },
            cols: 1,
            border: $scope.techSheetVars.border
          },
          data: data
        }
      };

      $scope.techSheetGenGeneratetechSheet(techSheetData);
    };

    $scope.techSheetGenGeneratetechSheet = data => {
      $scope.vars.loadAnimation = true;
      const user = Auth.getCurrentUser()._id;
      const recipe = $scope.vars.data._id;
      const url = '/api/label-generator/generate/' + user;
      const config = { responseType: 'arraybuffer' };

      $http.post(url, JSON.stringify(data), config).then(res => {
        var file = new Blob([res.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        $scope.vars.loadAnimation = false;
      }).catch(err => {
        console.log(err);
        $scope.vars.loadAnimation = false;
        alert('Errore durante la generazione della scheda tecnica');
      });
    };

    /**************************************************************************/
    /**************************************************************************/

    $scope.ingredientsBookParams = {
      paperSizes: [
        { w: 210, h: 297, name: 'A4 -' },
        { w: 148, h: 210, name: 'A5 -' }
      ],
      allergenStyles: [
        { style: 'bold', label: 'Grassetto' },
        { style: 'bold-underlined', label: 'Grassetto e sottolineato' }
      ]
    };

    $scope.ingredientsBookVars = {
      new: true,
      recipes: [],
      recipesFull: [],
      recipesIngredientsTableConfigs: [],
      recipesQuidsTableConfigs: [],
      ingredients: true,
      logoFile: {
        id: 'logo-img',
        label: 'Intestazione aziendale (formato PNG, JPG/JPEG o PDF)',
        value: '',
        userId: Auth.getCurrentUser()._id,
        preview: true
      },
      simpleIngredientsTitle: 'true',
      manufacturingPlantLab: 'stabilimento'
    };

    $scope.saveIngredientsBookSettings = () => {
      const user = Auth.getCurrentUser()._id;
      const settings = { settings: $scope.ingredientsBookVars };
      if(settings.settings.new) {
        settings.settings.new = false;
        settings._id = user;
        $http.post('/api/ingredientsbook-settings/', settings).then(() => {
          alert('Impostazioni salvate');
        }).catch(() => {
          settings.settings.new = true;
          alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
        });
      } else {
        $http.put('/api/ingredientsbook-settings/' + user, settings).then(() => {
          alert('Impostazioni salvate');
        }).catch(() => {
          alert('Errore durante il salvataggio delle impostazioni. Riprovare tra poco');
        });
      }
    };

    $scope.getRecipes = prefix => {
      return $http.get('/api/products/list/' + Auth.getCurrentUser()._id + '/' + prefix).then(res => {
        $scope.vars.searchList = res.data;
        $scope.vars.searchList.sort((a, b) => {
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        });
        return $scope.vars.searchList;
      });
    };

    $scope.addRecipe = function() {
      const isRecipe = $scope.vars.toAddRecipe.user;

      if(!isRecipe) {
        const recFromName = $scope.vars.searchList.find(e =>
          e.name.toLowerCase() === $scope.vars.toAddRecipe.toLowerCase());
        if(!recFromName) { return; }
        $scope.vars.toAddRecipe = recFromName;
      }

      const present = $scope.ingredientsBookVars.recipes.some(e =>
        e.name === $scope.vars.toAddRecipe.name);

      if(present) { return; }

      const newRecipe = {
        _id: $scope.vars.toAddRecipe._id,
        name: $scope.vars.toAddRecipe.name,
        productImgFile: $scope.vars.toAddRecipe.productImgFile || {
          id: 'product-img',
          label: 'Immagine del prodotto (formato PNG, JPG/JPEG o PDF)',
          value: '',
          userId: Auth.getCurrentUser()._id,
          preview: true
        }
      };

      const current = $scope.vars.toAddRecipe.name === $scope.vars.data.name;
      const fullRecipe = current ? $scope.vars.data : $scope.vars.toAddRecipe;
      const tableConfig = $scope.createRecipeIngredientsTableConfig($scope.vars.toAddRecipe.ingredients);
      const quidTableConfig = $scope.createRecipeQuidsTableConfig($scope.vars.toAddRecipe);

      fullRecipe.ingredients.forEach(e => {
        e.displayName = e.displayName || $scope.resetDisplayName(e);
      });

      $scope.ingredientsBookVars.recipes.push(newRecipe);
      $scope.ingredientsBookVars.recipesFull.push(fullRecipe);
      $scope.ingredientsBookVars.recipesIngredientsTableConfigs.push(tableConfig);
      $scope.ingredientsBookVars.recipesQuidsTableConfigs.push(quidTableConfig);

      $('#recipesTable-' + fullRecipe._id).on('editable-save.bs.table', function($el, field, row) {
        const elem = fullRecipe.ingredients.find(e => e.id === row.id);
        if(elem) { elem.displayName = row.displayName.trim(); }

        $http.put('/api/products/' + fullRecipe._id, fullRecipe).then(() => {
          // saved
        }).catch(err => {
          console.log(err);
        });
      });

      $('#recipesTable-' + fullRecipe._id).on('check.bs.table', function(e, row, $el) {
        const elem = fullRecipe.ingredients.find(e => e.id === row.id);
        if(elem) { elem.visible = true; }

        $http.put('/api/products/' + fullRecipe._id, fullRecipe).then(() => {
          // saved
        }).catch(err => {
          console.log(err);
        });
      });

      $('#recipesTable-' + fullRecipe._id).on('uncheck.bs.table', function(e, row, $el) {
        const elem = fullRecipe.ingredients.find(e => e.id === row.id);
        if(elem) { elem.visible = false; }

        $http.put('/api/products/' + fullRecipe._id, fullRecipe).then(() => {
          // saved
        }).catch(err => {
          console.log(err);
        });
      });

      const $quidsTable = $('#quidsTable-' + fullRecipe._id);

      $quidsTable.on('editable-save.bs.table', function($el, field, row) {
        const elem = fullRecipe.ingredients.find(e => e.id === row.id);
        if(elem) { elem.quidWording = row.quidWording; }

        $http.put('/api/products/' + fullRecipe._id, fullRecipe).then(() => {
          // saved
        }).catch(err => {
          console.log(err);
        });
      });

      $scope.resetQuidTableState($quidsTable, fullRecipe);

      $quidsTable.on('reset-view.bs.table', function(data) {
        $scope.resetQuidTableState($quidsTable, fullRecipe);
      });

      $scope.vars.toAddRecipe = null;
    };

    $scope.removeRecipe = ($event, recipe) => {
      if($event) {
        $event.stopPropagation();
        $event.preventDefault();
      }

      const index = $scope.ingredientsBookVars.recipes.findIndex(e => e.name === recipe.name);
      $scope.ingredientsBookVars.recipes.splice(index, 1);
      $scope.ingredientsBookVars.recipesFull.splice(index, 1);
      $scope.ingredientsBookVars.recipesIngredientsTableConfigs.splice(index, 1);
      $scope.ingredientsBookVars.recipesQuidsTableConfigs.splice(index, 1);
    };

    $scope.createRecipeIngredientsTableConfig = ingredients => {
      return {
        options: {
          data: ingredients,
          idField: 'id',
          search: false,
          maintainSelected: true,
          pagination: true,
          checkboxHeader: false,
          columns: [{
            field: 'id',
            title: 'Ingredienti',
            width: '30%',
            valign: 'middle',
            sortable: true,
            formatter: function(value, row) {
              return row.name;
            }
          }, {
            field: 'displayName',
            title: 'Denominazione su etichetta',
            valign: 'middle',
            sortable: true,
            editable: {
              clear: false,
              showbuttons: 'bottom',
              unsavedclass: null,
              type: 'text',
              inputclass: 'form-control',
              title: 'Denominazione su etichetta',
              emptytext: 'Denominazione vuota',
              validate: function(value) {
                if($.trim(value) == '') {
                  return 'Denominazione non valida';
                }
              }
            }
          }]
        }
      };
    }

    $scope.createRecipeQuidsTableConfig = recipe => {
      return {
        options: {
          data: recipe.ingredients.filter(e => e.includePerc),
          idField: 'id',
          search: false,
          maintainSelected: true,
          pagination: true,
          checkboxHeader: false,
          columns: [{
            field: 'id',
            title: 'Ingredienti',
            width: '30%',
            valign: 'middle',
            sortable: true,
            formatter: function(value, row) {
              return row.name;
            }
          }, {
            field: 'quidWording',
            title: 'Dicitura QUID',
            valign: 'middle',
            sortable: true,
            editable: {
              type: 'select',
              title: 'Dicitura QUID',
              sourceError: 'Please, select a State',
              source: function() {
                const prodName = recipe.name;
                const name = $(this).parent().siblings('td:first').text();
                const ingredient = recipe.ingredients.find(e => e.name === name);
                const quantity = ingredient.quantity;

                if(!ingredient.includePerc) {
                  return [
                    { value: 1, text: 'N/A' },
                    { value: 2, text: 'N/A' }
                  ];
                }

                const total = recipe.totalWeightPostPrep ||
                  recipe.ingredients.reduce((a, b) => a + b.quantity, 0);
                const quid = 100 * quantity / total;

                if(quid >= 100) {
                  const ending = name.slice(-1).toLowerCase();
                  return [
                    { value: 1, text: name + ' utilizzat' + ending + ' per 100g di prodotto: ' + quantity + 'g' },
                    { value: 2, text: 'Per la preparazione di 100g di ' + prodName + ' sono stati utilizzati ' + quantity + 'g di ' + name }
                  ];
                } else {
                  return [
                    { value: 1, text: name + ' (' + quid + '%)' },
                    { value: 2, text: name + ' (' + quid + '%)' }
                  ];
                }
              }
            }
          }]
        }
      };
    }

    $scope.loadIngredientsBookSettings = () => {
      const user = Auth.getCurrentUser()._id;
      $http.get('/api/ingredientsbook-settings/' + user).then((res) => {
        if(res.data) {
          $scope.ingredientsBookVars = res.data.settings;

          console.log(res.data);

          if($scope.ingredientsBookParams.paperSizes) {
            $scope.ingredientsBookVars.paperSize = $scope.ingredientsBookParams.paperSizes.find(e =>
              e.w === res.data.settings.paperSize.w &&
              e.h === res.data.settings.paperSize.h);
          }
        }
      });
    };

    $scope.loadIngredientsBookSettings();

    $scope.ingredientsBookGenGenerateIngredientsBookData = () => {
      if(!$scope.ingredientsBookVars.recipes.length) {
        return alert('Selezionare le ricette da aggiungere al libro ingredienti');
      }

      if(!$scope.ingredientsBookVars.paperSize || !$scope.ingredientsBookVars.paperSize.w) {
        return alert('Selezionare dimensione foglio');
      }

      if(!$scope.ingredientsBookVars.ingredientsBookType) {
        return alert('Selezionare tipo etichetta');
      }

      if($scope.ingredientsBookVars.isAppDate && !$scope.ingredientsBookVars.appDate) {
        return alert('Selezionare data di approvazione');
      }

      if($scope.ingredientsBookVars.isRevDate && !$scope.ingredientsBookVars.revDate) {
        return alert('Selezionare data di revisione');
      }

      if($scope.ingredientsBookVars.ingredients && !$scope.ingredientsBookVars.allergenStyle) {
        return alert('Selezionare stile evidenziazione allergeni');
      }

      if(($scope.ingredientsBookVars.ingredients) && !$scope.ingredientsBookVars.simpleIngredientsTitle) {
        return alert('Selezionare dicitura ingredienti / i nostri ingredienti');
      }

      if($scope.ingredientsBookVars.logo && !$scope.ingredientsBookVars.logoFile.value) {
        return alert('Caricare intestazione aziendale');
      }

      if($scope.ingredientsBookVars.qrcode && !$scope.ingredientsBookVars.qrcodeLink) {
        return alert('Inserire link QR code');
      }

      if($scope.ingredientsBookVars.manufacturingPlant &&
        !$scope.ingredientsBookVars.manufacturingPlantLab) {
        return alert('Selezionare dicitura stabilimento / laboratorio');
      }

      const recipesList =  $scope.ingredientsBookVars.recipes.map(e => {
        const recipe = {
          _id: e._id, name: e.name, productImgFile: e.productImgFile
        };

        if($scope.ingredientsBookVars.productImg) {
          recipe.productImgFileInfo = e.productImgFile;
          recipe.productImgFile = e.productImgFile.uniqueIdentifier;
        }

        return recipe;
      });

      const data = { recipes: recipesList };

      if($scope.ingredientsBookVars.isAppDate) {
        data.appDate = $scope.ingredientsBookVars.appDate;
      }

      if($scope.ingredientsBookVars.isRevDate) {
        data.revDate = $scope.ingredientsBookVars.revDate;
      }

      data.logo = $scope.ingredientsBookVars.logo;

      if(data.logo) {
        data.logoFile = $scope.ingredientsBookVars.logoFile.uniqueIdentifier;
        data.logoFileInfo = $scope.ingredientsBookVars.logoFile;
      }

      data.productImg = $scope.ingredientsBookVars.productImg;

      data.qrcode = $scope.ingredientsBookVars.qrcode;

      if(data.qrcode) {
        data.qrcodeLink = $scope.ingredientsBookVars.qrcodeLink;
      }

      data.barcode = $scope.ingredientsBookVars.barcode;

      data.name = $scope.ingredientsBookVars.name;
      data.recipeAdvice = $scope.ingredientsBookVars.recipeAdvice;
      data.claims = $scope.ingredientsBookVars.claims;
      data.conservationInfo = $scope.ingredientsBookVars.conservationInfo;
      data.lotto = $scope.ingredientsBookVars.lotto;
      data.manufacturer = $scope.ingredientsBookVars.manufacturer;
      data.packager = $scope.ingredientsBookVars.packager;
      data.manufacturingPlant = $scope.ingredientsBookVars.manufacturingPlant;
      data.manufacturingPlantLab = $scope.ingredientsBookVars.manufacturingPlantLab;
      data.countryOfOrigin = $scope.ingredientsBookVars.countryOfOrigin;
      data.productCode = $scope.ingredientsBookVars.productCode;
      data.shelfLife = $scope.ingredientsBookVars.shelfLife;
      data.detailedDescription = $scope.ingredientsBookVars.detailedDescription;

      if($scope.ingredientsBookVars.ingredientsBookType === "nutritional-values-table") {
        data.nutritionalValues = {
          for100g: true
        };

        if($scope.ingredientsBookVars.forUnit) {
          data.nutritionalValues.showForUnit = true;
        }

        if($scope.ingredientsBookVars.gda) {
          data.nutritionalValues.showGda = true;
        }

        data.nutritionalValues.monoinsatFats = $scope.ingredientsBookVars.monoinsatFats;
        data.nutritionalValues.polyinsatFats = $scope.ingredientsBookVars.polyinsatFats;
        data.nutritionalValues.polyols = $scope.ingredientsBookVars.polyols;
        data.nutritionalValues.starch = $scope.ingredientsBookVars.starch;
        data.nutritionalValues.fibres = $scope.ingredientsBookVars.fibres;
        data.nutritionalValues.colNutritionalVals = $scope.ingredientsBookVars.colNutritionalVals;
      }
      else if($scope.ingredientsBookVars.ingredientsBookType === "nutritional-values-text") {
        data.nutritionalValuesTextInfo = {
          for100g: true
        };

        if($scope.ingredientsBookVars.forUnit) {
          data.nutritionalValuesTextInfo.showForUnit = true;
        }

        if($scope.ingredientsBookVars.gda) {
          data.nutritionalValuesTextInfo.showGda = true;
        }

        data.nutritionalValuesTextInfo.monoinsatFats = $scope.ingredientsBookVars.monoinsatFats;
        data.nutritionalValuesTextInfo.polyinsatFats = $scope.ingredientsBookVars.polyinsatFats;
        data.nutritionalValuesTextInfo.polyols = $scope.ingredientsBookVars.polyols;
        data.nutritionalValuesTextInfo.starch = $scope.ingredientsBookVars.starch;
        data.nutritionalValuesTextInfo.fibres = $scope.ingredientsBookVars.fibres;
      }

      data.simpleIngredientsTitle = $scope.ingredientsBookVars.simpleIngredientsTitle === 'true';
      data.allergenStyle = $scope.ingredientsBookVars.allergenStyle;
      data.traceLabels = $scope.vars.traceLabels;

      data.multilingual = $scope.vars.data.multilingual;
      data.language = $scope.vars.language;

      const ingredientsBookData = {
        base: 'fixed',
        params: {
          layout: {
            page: {
              width: $scope.ingredientsBookVars.paperSize.w,
              height: $scope.ingredientsBookVars.paperSize.h
            },
            cols: 3,
            border: $scope.ingredientsBookVars.border,
            landscape: $scope.ingredientsBookVars.landscape,
            oneCol: $scope.ingredientsBookVars.oneCol
          },
          data: data
        }
      };

      $scope.ingredientsBookGenGenerateIngredientsBook(ingredientsBookData);
    };

    $scope.ingredientsBookGenGenerateIngredientsBook = data => {
      // console.log(data);
      $scope.vars.loadAnimation = true;
      const user = Auth.getCurrentUser()._id;
      const recipe = $scope.vars.data._id;
      const url = '/api/label-generator/generateIngredientsBook/' + user;
      const config = { responseType: 'arraybuffer' };

      $http.post(url, JSON.stringify(data), config).then(res => {
        var file = new Blob([res.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        $scope.vars.loadAnimation = false;
      }).catch(err => {
        console.log(err);
        $scope.vars.loadAnimation = false;
        alert('Errore durante la generazione del libro ingredienti');
      });
    };

    /**************************************************************************/
    /**************************************************************************/

    $scope.flowUploadStarted = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').start().autoIncrease(2, 500);
      model.state = 'started';
      model.value = $flow.files[0].name;
    };

    $scope.flowFileProgress = function($flow, model) {
      if($flow.files[0]) {
        progressJs('#' + model.id + ' .upload-progress').set($flow.files[0].progress() * 100);
      }
    };

    $scope.flowUploadComplete = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').end();
      if($flow.files[0]) {
        model.state = 'completed';
        model.uniqueIdentifier = $flow.files[0].uniqueIdentifier;

        if(model.id === 'product-img') {
          $scope.saveProduct();
        }
      } else {
        $scope.cancelUpload($flow, model);
      }
    };

    $scope.flowUploadFile = function($flow) {
      $flow.upload();
    };

    $scope.cancelUpload = function($flow, model) {
      progressJs('#' + model.id + ' .upload-progress').end();
      if($flow.files[0]) { $flow.files[0].cancel(); }
      model.value = '';
      model.state = '';
      model.uniqueIdentifier = '';
    };

    $scope.deleteUploaded = function($flow, model) {
      if($flow.files[0]) { $flow.files[0].cancel(); }
      // $http.get('/api/filetransfers/delete/' + model.uniqueIdentifier);
      model.value = '';
      model.state = '';
      model.uniqueIdentifier = '';
    };

    /**************************************************************************/

    $scope.toIngredient = () => {
      const converted = {
        tipo: 'Semilavorato',
        descrizione: $scope.vars.data.description,
        produttore: $scope.vars.data.manufacturer,
        energiaRicalcolataKcal: $scope.vars.nutrientsPer100g.energyKcal,
        energiaRicalcolataKJ: $scope.vars.nutrientsPer100g.energyKJ,
        lipidiTotaliG: $scope.vars.nutrientsPer100g.fats,
        lipidiAnimaliG: $scope.vars.nutrientsPer100g.satFats,
        grassiMonoinsaturiG: $scope.vars.nutrientsPer100g.monoinsatFats,
        grassiPolinsaturiG: $scope.vars.nutrientsPer100g.polyinsatFats,
        carboidratiDisponibiliMSEG: $scope.vars.nutrientsPer100g.carbohydrates,
        carboidratiSolubiliMSEG: $scope.vars.nutrientsPer100g.sugarCarbs,
        polioliG: $scope.vars.nutrientsPer100g.polyol,
        amidoG: $scope.vars.nutrientsPer100g.starch,
        fibraAlimentareTotaleG: $scope.vars.nutrientsPer100g.fibres,
        proteineTotaliG: $scope.vars.nutrientsPer100g.proteins,
        sodioMg: $scope.vars.nutrientsPer100g.salts * 1000 / 2.5,
        allergenico: $scope.vars.data.ingredients.some(e => e.allergen),
        ingredienti: $scope.vars.data.ingredients.reduce((a, e) => a.concat(e.info.ingredients.length ? e.info.ingredients : e.name), []).filter(e => e),
        listaAllergeni: $scope.vars.data.ingredients.reduce((a, e) => a.concat(e.info.ingredients.length ? e.info.allergens : e.info.allergenParts ? e.info.allergenParts.split(' ') : []), []).filter(e => e),
        listaTracce: $scope.vars.data.traces,
        user: Auth.getCurrentUser().email,
        recipe: true,
        recipeId: $scope.vars.data._id,
        multilingual: $scope.vars.data.multilingual
      };

      if(converted.multilingual) {
        converted.titoloI = $scope.vars.data.name;
        converted.titoloF = $scope.vars.data.name;
        converted.titoloD = $scope.vars.data.name;
        converted.titoloE = $scope.vars.data.name;
      } else {
        converted.titolo = $scope.vars.data.name;
      }

      $http({
        url: '/api/alimenti/recipeToIngredient', method: 'PUT', data: converted
      }).then(() => {
        alert('Ricetta salvata come ingrediente con successo');
      }).catch(err => {
        alert('Errore durante il salvataggio. Riprovare più tardi');
      });
    };

    /**************************************************************************/

    $scope.languageChanged = function() {
      $('#table').bootstrapTable('refreshOptions', $scope.vars.tableConfig.options);
      $('#priceTable').bootstrapTable('refreshOptions', $scope.vars.priceTableConfig.options);
    }

    $scope.langToCod = language => {
      switch(language) {
          case 'Tedesco': return 'D';
          case 'Francese': return 'F';
          case 'Italiano': return 'I';
          case 'Inglese': return 'E';
          default: return '';
      }
    };

    $scope.formatIngredientName = ingredient => {
      const nameField = 'name' + ($scope.vars.data.multilingual ?
        $scope.langToCod($scope.vars.language) : '');
      return ingredient[nameField].replace(',', '');
    };

    $scope.getTranslatedUnitType = unitType => {
      return $scope.getTranslation(unitType, 'translationsForUnitTypes');
    };

    $scope.getTranslatedMaterial = material => {
      return $scope.getTranslation(material, 'translationsForMaterials');
    };

    $scope.getTranslation = (toTranslate, translations) => {
      const multilingual = $scope.vars.data.multilingual;
      const language = $scope.vars.language;

      if(!toTranslate || !multilingual) {
        return toTranslate;
      }

      if(translations === 'translationsForMaterials' && $scope.vars.multilingualLabel) {
        const selectedLangs = {
          Italiano: $scope.labelVars.italian,
          Inglese: $scope.labelVars.english,
          Tedesco: $scope.labelVars.german,
          Francese: $scope.labelVars.french
        }

        return Object.keys(selectedLangs)
          .filter(l => selectedLangs[l])
          .map(l => $scope.vars[translations][toTranslate][l])
          .join(' \\textbackslash{} ');
      }

      return $scope.vars[translations][toTranslate][language]
    };

    /**************************************************************************/

    $scope.barcodeChanged = function(noSave) {
      if(!$scope.vars.data.barcodeData) {
        $scope.vars.barcodePreviewState = 'noData';
        return;
      }

      JsBarcode("#barcodePreview", $scope.vars.data.barcodeData, {
        format: $scope.vars.data.barcodeType,
        fontSize: 18,
        textMargin: 0,
        valid: valid => {
          $scope.vars.barcodePreviewState = valid ? 'display' : 'invalid';

          if(valid && !noSave) {
            $scope.saveProduct();
          }
        }
      });
    }

    /**************************************************************************/

    $scope.expTypeTimeToIt = function(timeUnit) {
      switch(timeUnit) {
        case 'days': return 'Giorni';
        case 'months': return 'Mesi';
        case 'years': return 'Anni';
      }
    }

    $scope.changeExpTimeType = function(timeUnit) {
      $scope.vars.data.expTimeType = timeUnit;
      $scope.saveProduct();
    }

    $scope.saveForNewProducts = function() {
      $scope.labelVars.expTime = $scope.vars.data.expTime;
      $scope.labelVars.expTimeType = $scope.vars.data.expTimeType;
      $scope.labelVars.expTimeFrom = $scope.vars.data.expTimeFrom;
      $scope.labelVars.expDateType = $scope.vars.data.expDateType;
      $scope.labelVars.expDateSubType = $scope.vars.data.expDateSubType;
      $scope.saveLabelSettings();
    }

    /**************************************************************************/

    // Generate custom label

    $scope.labelGenGenerateCustomLabel = function() {
      if(!$scope.labelGenParamsCheck()) {
        return;
      }

      $scope.labelContents.text.forEach(e => {
        const svg = document.getElementById('svg-' + e.id);
        e.svg = svg ? svg.outerHTML : null;
      });

      const tableInfo = $scope.labelContents.table
      const tableSvg = document.getElementById('svg-' + tableInfo.id);
      tableInfo.svg = tableSvg ? tableSvg.outerHTML : null;

      const logoInfo = $scope.labelContents.images.logo;
      const logoSvg = document.getElementById('svg-' + logoInfo.id);
      logoInfo.svg = logoSvg ? logoSvg.outerHTML : null;

      const barcodeInfo = $scope.labelContents.images.barcode;
      const barcodeSvg = document.getElementsByClassName(barcodeInfo.id)[0];
      barcodeInfo.svg = barcodeSvg ? barcodeSvg.outerHTML : null;

      const qrcodeInfo = $scope.labelContents.images.qrcode;
      const qrcodeSvg = document.getElementById('svg-' + qrcodeInfo.id);
      qrcodeInfo.svg = qrcodeSvg ? qrcodeSvg.outerHTML : null;

      const recycleInfo = $scope.labelContents.images.recycleInfo;
      const recycleInfoSvg = document.getElementById('svg-' + recycleInfo.id);
      recycleInfo.svg = recycleInfoSvg ? recycleInfoSvg.outerHTML : null;

      const textNutValsInfo = $scope.labelContents.other.textNutVals
      const textNutValsSvg = document.getElementById('svg-' + textNutValsInfo.id);
      textNutValsInfo.svg = textNutValsSvg ? textNutValsSvg.outerHTML : null;

      const grid = document.getElementById('grid-stack');
      const cellWidth = grid.offsetWidth / 12;
      const cellHeight = $scope.customLabelParams.cellHeight;

      const dpi = 96; // 72 or 96?
      // const dpi = 98.5; // 72 or 96?
      // const ratio = (210 * dpi) / (grid.offsetWidth * 25.4);
      const ratio =
        ($scope.labelVars.paperSize.w * dpi) / (grid.offsetWidth * 25.4);
      const svg = Snap(grid.offsetWidth * ratio + 'px', grid.offsetHeight * ratio + 'px');
      // const svg = Snap(grid.offsetWidth, grid.offsetHeight);
      svg.attr({
        id: 'customLabelPreview',
        viewBox: '0 0 ' + grid.offsetWidth + ' ' + grid.offsetHeight
      });

      $(customLabelPreview).remove();

      // const preview = Snap('#previewSVG');
      // preview.append(svg);

      const components = [
        ...$scope.labelContents.text,
        tableInfo,
        logoInfo,
        barcodeInfo,
        qrcodeInfo,
        recycleInfo,
        textNutValsInfo
      ].filter(e => e.svg).map(e => {
        const outer = Snap(e.layout.w * cellWidth, e.layout.h * cellHeight)
          .attr({ x: e.layout.x * cellWidth, y: e.layout.y * (cellHeight + 1) });
        svg.append(outer);

        const inner = Snap.parse(e.svg);
        const id = 'svg#' + (e.id === 'barcodePreview' ? '' : 'svg-') + e.id;
        outer.append(inner).select(id)
          .attr({ x: 20, width: e.layout.w * cellWidth - 40 });

        return outer;
      });

      const url = '/api/customlabel-generator/generate/label';
      const data = {
        svg: svg.toString(),
        editor: {
          cell: {
            width: document.getElementById('grid-stack').offsetWidth / 12,
            height: $scope.customLabelParams.cellHeight
          },
          padding: { horizontal: 20, vertical: 0 },
          margin: { horizontal: 1, vertical: 1 }
        },
        page: {
          width: $scope.labelVars.paperSize.w,
          height: $scope.labelVars.paperSize.h
        }
      }

      // console.log(components);
      // console.log(data);
      // console.log('**********************************************************');

      // TODO: copy from labelGenGenerateLabel

      $scope.vars.loadAnimation = true;
      const config = { responseType: 'arraybuffer' };

      $http.post(url, JSON.stringify(data), config).then(res => {
        // console.log(res.data);
        var file = new Blob([res.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
        $scope.vars.loadAnimation = false;
      }).catch(err => {
        console.log(err);
        $scope.vars.loadAnimation = false;
        alert('Errore durante la generazione dell\'etichetta');
      });

      // TODO: remove svg2pdf!!!
      // const pdf = new jsPDF('p', 'px', [grid.offsetWidth, grid.offsetHeight]);
      // svg2pdf(document.getElementById('previewSVG'), pdf, { xOffset: 0, yOffset: 0, scale: 1 });
      // pdf.save("myPDF.pdf");
    };

    $scope.toggleCustomLabelMode = function() {
      if($scope.labelVars.customLayout && $scope.labelVars.paperType !== 'fixed') {
        $scope.labelVars.paperType = 'fixed';
        $scope.labelGenPaperTypeChange($scope.labelVars.paperType);
      }
    }

    /**************************************************************************/

    // CUSTOM LABEL

    $scope.customLabelParams = {
      cellHeight: 30,
      verticalMargin: 1,
      animate: false//,
      // float: true
    };

    $scope.customLabelFontSizes = {
      options: [...Array(11).keys()].map(e => e + 10),
      selected: 10
    };

    // TESTING

    $scope.initLabelEditor = () => {
      $scope.labelContents = {
        text: [
          {
            id: 'name',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              title: ($scope.vars.data.name || '').toUpperCase(),
            },
            font: {
              title: { size: 10, weight: 'bold' }
            }
          }, {
            id: 'description',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              title: ($scope.vars.data.description || '').toUpperCase(),
            },
            font: {
              title: { size: 8, weight: 'normal' }
            }
          }, {
            id: 'conservationInfo',
            layout: { x: 0, y: 0, w: 12, h: 10 },
            contents: {
              title: 'CONDIZIONI DI CONSERVAZIONE ED USO',
              body: 'conservationInfo'
            },
            font: {
              title: { size: 4, weight: 'bold' },
              body: { size: 0, weight: 'normal' }
            }
          }, {
            id: 'recipeAdvice',
            layout: { x: 0, y: 0, w: 12, h: 10 },
            contents: {
              title: 'CONSIGLI SULLA RICETTA',
              body: 'notes'
            },
            font: {
              title: { size: 4, weight: 'bold' },
              body: { size: 0, weight: 'normal' }
            }
          }, {
            id: 'weight',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olBody: $scope.vars.data.weight + $scope.vars.data.weightUnit
            },
            font: {
              olBody: { size: 6, weight: 'bold' }
            }
          }, {
            id: 'prodDate',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'DATA DI PRODUZIONE: ',
              olBody: $scope.vars.data.prodDate ? moment($scope.vars.data.prodDate).format('DD/MM/YYYY') : ''
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }, {
            id: 'packDate',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'DATA DI CONFEZIONAMENTO: ',
              olBody: $scope.vars.data.packDate ? moment($scope.vars.data.packDate).format('DD/MM/YYYY') : ''
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }, {
            id: 'expDate',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'DATA DI SCADENZA / TERMINE MINIMO DI CONSERVAZIONE: ',
              olBody: $scope.vars.data.expDate ? moment($scope.vars.data.expDate).format('DD/MM/YYYY') : ''
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }, {
            id: 'lotto',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'LOTTO DI PRODUZIONE: ',
              olBody: ($scope.vars.data.lotto || '').toUpperCase()
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }, {
            id: 'manufacturer',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'NOME O RAGIONE SOCIALE DEL PRODUTTORE: ',
              olBody: ($scope.vars.data.manufacturer || '').toUpperCase()
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }, {
            id: 'packager',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'NOME O RAGIONE SOCIALE DEL CONFEZIONATORE: ',
              olBody: ($scope.vars.data.packager || '').toUpperCase()
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }, {
            id: 'manufacturingPlant',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'SEDE DELLO STABILIMENTO DI PRODUZIONE: ',
              olBody: ($scope.vars.data.manufacturingPlant || '').toUpperCase()
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }, {
            id: 'countryOfOrigin',
            layout: { x: 0, y: 0, w: 12, h: 1 },
            contents: {
              olTitle: 'PAESE DI ORIGINE O LUOGO DI PROVENIENZA: ',
              olBody: ($scope.vars.data.countryOfOrigin || '').toUpperCase()
            },
            font: {
              olTitle: { size: 4, weight: 'bold' },
              olBody: { size: 4, weight: 'normal' }
            }
          }
        ],
        table: {
          id: 'nvTable',
          layout: { x: 0, y: 0, w: 12, h: 10 }
        },
        images: {
          logo: {
            id: 'logo',
            src: '',
            layout: { x: 0, y: 0, w: 12, h: 10 }
          },
          barcode: {
            id: 'barcodePreview',
            layout: { x: 0, y: 0, w: 12, h: 10 }
          },
          qrcode: {
            id: 'qrcode',
            src: '',
            layout: { x: 0, y: 0, w: 12, h: 10 }
          },
          recycleInfo: {
            id: 'recycleInfo',
            layout: { x: 0, y: 0, w: 12, h: 10 }
          }
        },
        other: {
          textNutVals: {
            id: 'textNutVals',
            layout: { x: 0, y: 0, w: 12, h: 4 },
            contents: {
              title: 'INFORMAZIONI NUTRIZIONALI',
              bodyExp: [
                'VALORI MEDI PER 100g: ENERGIA ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.energyKJ),
                ' kJ / ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.energyKcal),
                ' kcal, GRASSI ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.fats),
                ' g DI CUI: ACIDI GRASSI SATURI ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.satFats),
                ' g , ACIDI GRASSI MONOINSATURI ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.monoinsatFats),
                ' g , ACIDI GRASSI POLINSATURI ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.polyinsatFats),
                ' g ; CARBOIDRATI ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.carbohydrates),
                ' g DI CUI: ZUCCHERI ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.sugarCarbs),
                ' g , POLIOLI ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.polyol),
                ' g, AMIDO ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.starch),
                ' g ; FIBRE ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.fibres),
                ' g, PROTEINE ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.proteins),
                ' g, SALE ',
                $scope.convertTo2dp($scope.vars.nutrientsPer100g.salts),
                ' g \n VALORI MEDI PER ',
                $scope.vars.data.weightPieceType.toUpperCase(),
                ' (',
                $scope.vars.data.weightPerPiece,
                'g): ENERGIA ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.energyKJ),
                ' kJ / ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.energyKcal),
                ' kcal, GRASSI ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.fats),
                ' g DI CUI: ACIDI GRASSI SATURI ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.satFats),
                ' g , ACIDI GRASSI MONOINSATURI ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.monoinsatFats),
                ' g , ACIDI GRASSI POLINSATURI ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.polyinsatFats),
                ' g ; CARBOIDRATI ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.carbohydrates),
                ' g DI CUI: ZUCCHERI ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.sugarCarbs),
                ' g , POLIOLI ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.polyol),
                ' g , AMIDO ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.starch),
                ' g ; FIBRE ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.fibres),
                ' g, PROTEINE ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.proteins),
                ' g, SALE ',
                $scope.convertTo2dp($scope.vars.nutrientsPerPiece.salts),
                ' g \n VALORI MEDI PER ASSUNZIONE DI RIFERIMENTO DI UN ADULTO MEDIO (8400kJ/2000kcal): ENERGIA ',
                $scope.convertTo2dp($scope.vars.gda.energyKcal),
                ' %, GRASSI ',
                $scope.convertTo2dp($scope.vars.gda.fats),
                ' % DI CUI: ACIDI GRASSI SATURI ',
                $scope.convertTo2dp($scope.vars.gda.satFats),
                ' % ; CARBOIDRATI ',
                $scope.convertTo2dp($scope.vars.gda.carbohydrates),
                ' % DI CUI: ZUCCHERI ',
                $scope.convertTo2dp($scope.vars.gda.sugarCarbs),
                ' % ; FIBRE ',
                $scope.convertTo2dp($scope.vars.gda.fibres),
                ' %, PROTEINE ',
                $scope.convertTo2dp($scope.vars.gda.proteins),
                ' %, SALE ',
                $scope.convertTo2dp($scope.vars.gda.salts),
                ' %'
              ].join(' ')
            },
            font: {
              title: { size: 4, weight: 'bold' },
              body: { size: 0, weight: 'normal' }
            }
          }
        }
      };

      if($scope.vars.labelGen) {
        $scope.loadLogoPreview();
        $scope.barcodeChanged();
        $scope.reloadQrcode();
        $scope.toggleCustomLabelMode();
      }
    };

    $scope.redrawText = function(item) {
      const svgNS = "http://www.w3.org/2000/svg";
      const svg = document.getElementById('svg-' + item.id);
      // const width = svg.getBoundingClientRect().width - 40;
      const width = svg.getBoundingClientRect().width - 50;
      const textElem = document.getElementById('bodyNode-' + item.id);
      const words = (item.contents.bodyExp ?
        item.contents.bodyExp.split(' ') :
        $scope.vars.data[item.contents.body].replace(/\s+/g, ' ').split(' '))
        .map(e => e.trim().toUpperCase());
      var tspanElem = document.createElementNS(svgNS, "tspan");
      var textNode = document.createTextNode(words[0]);
      var wordsPerLine = 1;
      var wordCharsCountInLine = [];
      wordCharsCountInLine.push(words[0].length);

      while (textElem.lastChild) { textElem.removeChild(textElem.lastChild); }

      tspanElem.appendChild(textNode);
      textElem.appendChild(tspanElem);

      for(var i = 1; i < words.length; i++) {
          var len = tspanElem.firstChild.data.length;
          var space = wordsPerLine ? ' ' : '';
          tspanElem.firstChild.data += space + words[i];
          wordsPerLine++;
          wordCharsCountInLine.push(words[i].length);

          if (tspanElem.getComputedTextLength() > width || words[i] === '\n') {
              tspanElem.firstChild.data = tspanElem.firstChild.data.slice(0, len);
              wordsPerLine--;
              wordCharsCountInLine.pop();

              if(words[i] !== '\n') {
                const lineWidth = tspanElem.getComputedTextLength();
                const wordSpacing = (width - lineWidth) / (wordsPerLine - 1) - 0.72;
                const dx = wordCharsCountInLine.map(e => '0 '.repeat(e))
                  .join(wordSpacing + 'px ').trim();

                // tspanElem.setAttributeNS(null, "word-spacing", wordSpacing);
                // tspanElem.setAttributeNS(null, 'dx', dx);
              }

              // TODO: fix for variable font size!!!!!

              // console.log('*******************************');
              // console.log('SVG width: ' + width);
              // console.log('Sliced width: ' + lineWidth);
              // console.log('WordsPerLine: ' + wordsPerLine);
              // console.log('Calcd Word spacing: ' + wordSpacing);

              var tspanElem = document.createElementNS(svgNS, "tspan");
              tspanElem.setAttributeNS(null, "x", 0);
              // tspanElem.setAttributeNS(null, "dy", 14);
              tspanElem.setAttributeNS(null, "dy", $scope.customLabelFontSizes.selected + 4);
              wordCharsCountInLine = [];

              if(words[i] !== '\n') {
                textNode = document.createTextNode(words[i]);
                wordsPerLine = 1;
                wordCharsCountInLine.push(words[i].length);
              } else {
                textNode = document.createTextNode('');
                wordsPerLine = 0;
              }

              tspanElem.appendChild(textNode);
              textElem.appendChild(tspanElem);
          }
      }
    }

    $scope.redrawAllText = function() {
      setTimeout(function() {
        $scope.labelContents.text
          .filter(e => $scope.labelVars[e.id] && e.contents.body)
          .forEach(e => $scope.redrawText(e));

        if($scope.labelVars.labelType === 'nutritional-values-text') {
          $scope.redrawText($scope.labelContents.other.textNutVals);
        }
      }, 20);
    }

    $scope.getSvgTextWidth = function(id) {
      const olTitle = document.getElementById('olTitle-' + id);
      return olTitle ? olTitle.getComputedTextLength() + 10 : 0;
    }

    $scope.getSvgNodeHeight = function(id) {
      const node = document.getElementById(id);
      if(!node) { return 0; }
      return node.getBoundingClientRect().height;
    }

    // SVG TABLE ///////////////////////////////////////////////////////////////

    $scope.getSvgColumnX = function(colIndex) {
      // const labelGen = $scope.vars.labelGen;
      // const tableVis = $scope.labelVars.labelType === 'nutritional-values-table';
      //
      // if(!(labelGen && tableVis)) { return; }

      if(colIndex === 0) { return 0; }
      if(colIndex === 3 && !$scope.labelVars.forUnit) { colIndex -= 1; }

      const col1ColSpan = 3; // colspan 3 for 1st column
      const columns = col1ColSpan + 1 + $scope.labelVars.forUnit +
        $scope.labelVars.gda; // NB: implicit bool -> 1/0 conversion
      const colWidth = 100 / columns;
      return ((col1ColSpan - 1) + colIndex) * colWidth;
    };

    ////////////////////////////////////////////////////////////////////////////

    $scope.loadLogoPreview = () => {
      if($scope.labelVars.logoFile.value) {
        const data = {
          id: $scope.labelVars.logoFile.uniqueIdentifier,
          name: $scope.labelVars.logoFile.value,
          component: $scope.labelContents.images.logo
        };

        $scope.getImg(data);
      }
    };

    $scope.getImg = data => {
      const id = data.id;
      const name = data.name;
      const url = '/api/filetransfers/download/' + id + '/' + name;
      const config = { responseType: 'arraybuffer' };

      $http.get(url, config).then(res => {
        data.component.src = 'data:image/xyz;base64,' +
          $scope._arrayBufferToBase64(res.data);
      }).catch(err => {
        console.log(err);
        alert('Errore durante il caricamento dell\'immagine');
      });
    };

    $scope._arrayBufferToBase64 = function( buffer ) {
      var binary = '';
      var bytes = new Uint8Array( buffer );
      var len = bytes.byteLength;
      for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
      }
      return window.btoa( binary );
    };

    $scope.imgToBase64 = function(path, id) {
      const img = new Image();

      img.onload = function() {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        $(id).attr('href', dataURL);
      };

      img.src = path;
    };

    // function encodeImageFileAsURL(element) {
    //   var file = element.files[0];
    //   var reader = new FileReader();
    //   reader.onloadend = function() {
    //     console.log('RESULT', reader.result)
    //   }
    //   reader.readAsDataURL(file);
    // }

    ////////////////////////////////////////////////////////////////////////////

    $scope.reloadQrcode = function() {
      if($scope.labelVars.qrcodeLink) {
        const qr = new QRious({
          size: 500, level: 'H', value: $scope.labelVars.qrcodeLink
        });
        $scope.labelContents.images.qrcode.src = qr.toDataURL();
      } else {
        $scope.labelContents.images.qrcode.src = '';
      }
    }

    ////////////////////////////////////////////////////////////////////////////

    $scope.recycleColorToImg = function(colour) {
      let path = "/assets/images/recycling_";

      switch(colour) {
        case 'Bianco': path += 'white'; break;
        case 'Giallo': path += 'yellow'; break;
        case 'Verde': path += 'green'; break;
        case 'Blu': path += 'blue'; break;
        case 'Rosso': path += 'red'; break;
        case 'Grigio': path += 'grey'; break;
      }

      return path + '.png';
    }

    ////////////////////////////////////////////////////////////////////////////

    $scope.onChange = function(event, items) {
        // console.log("onChange event: "+event+" items:"+items);

        if($scope.labelContents && items && items[0] && items[0].el && items[0].el[0]) {
          items.forEach(i => {
            let item = $scope.labelContents.text.find(e =>
              'item-' + e.id === i.el[0].id);
            // console.log(item);
            // console.log($scope.labelContents);
            if(!item) {
              if('item-' + $scope.labelContents.other.textNutVals.id === i.el[0].id) {
                item = $scope.labelContents.other.textNutVals;
              }
            }

            if(item && (item.contents.body || item.contents.bodyExp)) {
              $scope.redrawText(item);
            }
          });
        }
    };
    $scope.onDragStart = function(event, ui) {
        // console.log("onDragStart event: "+event+" ui:"+ui);
    };
    $scope.onDragStop = function(event, ui) {
        // console.log("onDragStop event: "+event+" ui:"+ui);
    };
    $scope.onResizeStart = function(event, ui) {
        // console.log("onResizeStart event: "+event+" ui:"+ui);
    };
    $scope.onResizeStop = function(event, ui) {
        // console.log("onResizeStop event: ");
        // console.log(event);
        // console.log("ui: ");
        // console.log(ui);
        // console.log('*******************');
        // console.log($scope.labelContents);
        // console.log(event);
    };

  });
